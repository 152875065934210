import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import reducers from "./redux/reducers";
import { persistStore, persistReducer } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
//import hardSet from "redux-persist/lib/stateReconciler/hardSet";
import localForage from "localforage";

import { MsalProvider } from "@azure/msal-react";
import { Configuration, PublicClientApplication } from "@azure/msal-browser";

const persistConfig = {
  key: "root",
  storage: localForage,
  //stateReconciler: hardSet,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const middleware = compose(
  applyMiddleware(thunk),
  window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__()
    : (f) => f
);

const store = createStore(persistedReducer, middleware);
const persistor = persistStore(store);

//msal configuration
const configuration: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_MS_CLIENT_ID,
    authority: "https://login.microsoftonline.com/organizations/",
    redirectUri: process.env.REACT_APP_MS_DOMAIN_NAME,
    postLogoutRedirectUri: process.env.REACT_APP_MS_DOMAIN_NAME,
  },
};
const pca = new PublicClientApplication(configuration);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <MsalProvider instance={pca}>
          <App />
        </MsalProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
