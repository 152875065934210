import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import MachinesList from "./machinesList";
import MachineSearch from "./machineSearch";
import SearchResult from "./searchResult";
import AddMachineForm from "./addMachineForm";
import EditMachineForm from "./editMachineForm";
import { sortByNumber } from "../../tools";

const MachinesPage = ({ machines }) => {
  const match = useRouteMatch();
  const [machinesList, setMachinesList] = useState([]);

  useEffect(() => {
    const lastAddedMachines = sortByNumber([...machines], "machine_id").slice(
      0,
      30
    );
    setMachinesList(lastAddedMachines);
  }, [machines]);

  return (
    <div>
      <div className="header">
        <MachineSearch />
      </div>
      <div className="content">
        <Switch>
          <Route path={`${match.path}/add-machine`}>
            <AddMachineForm />
          </Route>
          <Route path={`${match.path}/edit-machine`}>
            <EditMachineForm />
          </Route>
          <Route path={`${match.path}/search-results`}>
            <SearchResult />
          </Route>
          <MachinesList list={machinesList} />
        </Switch>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  machines: state.machines,
});

export default connect(mapStateToProps)(MachinesPage);
