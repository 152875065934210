import {
  getMachines,
  getLastUpdated,
  postToES,
  putToES,
  deleteToES,
} from "../../apiES";
import { sortByDate } from "../../components/tools";
import { countAndCompareType } from "../countAndCompareType";

const addMachines = (machines) => ({
  type: "ADD_MACHINES",
  payload: machines,
});

const updateMachines = (machines) => ({
  type: "UPDATE_MACHINES",
  payload: machines,
});

const fetchAllMachines = () => {
  return (dispatch) => {
    getMachines().then((data) => {
      if (typeof data !== "undefined") {
        dispatch(addMachines(data));
      }
    });
  };
};

/* Replaced with fetchUpdatedMachines

const fetchLatestAddedMachines = () => {
  return (dispatch, getState) => {
    const machinesList = [...getState().machines];
    const latestMachineId = sortByDate([...machinesList], "created_at");
    getLatestMachines(getState().esToken, latestMachineId[0].id).then(
      (data) => {
        if (typeof data !== "undefined") {
          dispatch(updateMachines(data));
        }
      }
    );
  };
};
*/

const fetchUpdatedMachines = () => {
  return (dispatch, getState) => {
    const machinesList = [...getState().machines];
    const latestMachineDate = sortByDate([...machinesList], "updatedAt");
    getLastUpdated("machines", latestMachineDate[0].updatedAt).then((data) => {
      if (typeof data !== "undefined" && data.length !== 0) {
        dispatch(updateMachines(data));
      }
    });
  };
};

export const fetchMachines = () => {
  return async (dispatch, getState) => {
    const machineLength = getState().machines.length;
    const inSyncWithApi = await countAndCompareType("machines", machineLength);

    if (machineLength > 0 && inSyncWithApi) {
      dispatch(fetchUpdatedMachines());
    } else {
      dispatch(fetchAllMachines());
    }
  };
};

const addMachine = (machine) => ({
  type: "ADD_MACHINE",
  payload: machine,
});

export const postMachine = (machine) => {
  return (dispatch) => {
    //console.log(getState().machines);
    postToES("machines", machine)
      .then((data) => dispatch(addMachine(data)))
      .catch((error) => console.log(error));
  };
};

const editMachine = (machine) => ({
  type: "EDIT_MACHINE",
  payload: machine,
});

export const putMachine = (machine) => {
  return (dispatch) => {
    putToES("machines", machine)
      .then(() => dispatch(editMachine(machine)))
      .catch((error) => console.log(error));
  };
};

const deleteMachine = (machine) => ({
  type: "DELETE_MACHINE",
  payload: machine,
});

export const deleteMachines = (machines) => ({
  type: "DELETE_MACHINES",
  payload: machines,
});

export const removeMachine = (machine) => {
  return (dispatch) => {
    deleteToES("machines", machine)
      .then(() => dispatch(deleteMachine(machine)))
      .catch((err) => console.log(err));
  };
};

export const addFilteredMachine = (machine) => ({
  type: "ADD_FILTERED_MACHINE",
  payload: machine,
});

export const addFilteredMachines = (machine) => ({
  type: "ADD_FILTERED_MACHINES",
  payload: machine,
});
