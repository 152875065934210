import { combineReducers } from "redux";
import localForage from "localforage";

import { members, filteredMembers } from "./members/reducer";
import { makers } from "./makers/reducer";
import { companies, filteredCompanies } from "./companies/reducer";
import { machines, filteredMachines } from "./machines/reducer";
import { categories } from "./categories/reducer";

const initalState = {
  filteredMembers: [],
  isLoading: true,
  tags: [],
  msToken: [],
  loggedIn: false,
  fetchedMembersTime: [],
  esToken: null,
  isEsLoading: true,
  fetchedESTime: [],
  machines: [],
};

export const isLoading = (state = initalState.isLoading, action) => {
  switch (action.type) {
    case "SET_LOADING":
      return action.payload;

    default:
      return state;
  }
};

export const tags = (state = initalState.tags, action) => {
  switch (action.type) {
    case "ADD_TAG":
      for (let i = 0; i < state.length; i++) {
        if (state[i].id === action.payload.id) {
          return state;
        }
      }
      return [...state, { ...action.payload }];
    case "ADD_TAGS":
      return action.payload;

    default:
      return state;
  }
};

export const msToken = (state = initalState.msToken, action) => {
  switch (action.type) {
    case "ADD_MS_TOKEN":
      return (state = action.payload);
    case "REMOVE_MS_TOKEN":
      return (state = initalState.msToken);

    default:
      return state;
  }
};

export const loggedIn = (state = initalState.loggedIn, action) => {
  switch (action.type) {
    case "SET_LOGGED_IN":
      return !state;

    default:
      return state;
  }
};

export const fetchedMembersTime = (
  state = initalState.fetchedMembersTime,
  action
) => {
  switch (action.type) {
    case "SET_FETCH_MEMBERS_TIME":
      return action.payload;

    default:
      return state;
  }
};

export const fetchedESTime = (state = initalState.fetchedESTime, action) => {
  switch (action.type) {
    case "SET_FETCH_ES_TIME":
      return action.payload;
    default:
      return state;
  }
};

export const esToken = (state = initalState.esToken, action) => {
  switch (action.type) {
    case "ADD_ES_TOKEN":
      return (state = action.payload);
    default:
      return state;
  }
};

export const isEsLoading = (state = initalState.isEsLoading, action) => {
  switch (action.type) {
    case "SET_ES_LOADING":
      return (state = action.payload);
    default:
      return state;
  }
};

const reducers = combineReducers({
  members,
  filteredMembers,
  isLoading,
  tags,
  msToken,
  loggedIn,
  fetchedMembersTime,
  fetchedESTime,
  makers,
  categories,
  machines,
  filteredMachines,
  companies,
  filteredCompanies,
  esToken,
  isEsLoading,
});

const rootReducer = (state, action) => {
  if (action.type === "RESET") {
    localForage.removeItem("persist:root").then(() => {
      console.log("remove persist");
      state = initalState;
    });
  }
  return reducers(state, action);
};
export default rootReducer;
