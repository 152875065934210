export const members = (state = [], action) => {
  switch (action.type) {
    case "ADD_MEMBER":
      return [...state, action.payload];
    case "RECIVE_MEMBERS":
      console.log("reciving members");
      return action.payload;
    case "EDIT_MEMBER":
      console.log(`${action.payload.email_address} Member edited`);
      const newState = state.map((m) =>
        m.id === action.payload.id ? (m = { ...action.payload }) : m
      );
      return newState;
    case "REMOVE_MEMBER":
      console.log(`${action.payload} Member removed`);
      const stateWithRemovedMember = state.filter(
        (m) => m.id !== action.payload
      );
      return stateWithRemovedMember;
    default:
      return state;
  }
};

export const filteredMembers = (state = [], action) => {
  switch (action.type) {
    case "ADD_FILTERED_MEMBER":
      return [...state, { payload: action.payload }];
    case "ADD_FILTERED_MEMBERS":
      return action.payload;

    default:
      return state;
  }
};
