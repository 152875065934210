import { getTypeCount } from "../apiES";

/**
 * Fetches api count for type and compares
 * @param {string} type ex. machines
 * @param {number} typeCount Client type count
 * @returns true if client count not exccedes apiCount else false
 */
export const countAndCompareType = (type, typeCount) => {
  return getTypeCount(type).then((apiCount) => {
    if (typeCount > apiCount) {
      console.log(type, " not in sync with api ", typeCount, "Api: ", apiCount);
      return false;
    } else {
      return true;
    }
  });
};
