import { getEsToken } from "../apiES";

import { fetchCategories } from "./categories/actions";
import { fetchMakers } from "./makers/actions";
import { fetchCompanies } from "./companies/actions";
import { fetchMachines } from "./machines/actions";

export const setLoading = (status) => ({
  type: "SET_LOADING",
  payload: status,
});

export const resetState = () => ({
  type: "RESET",
});

// ------ Microsoft azure login actions ------ !!! Replaced with msal-react !!!
/*
const addMSToken = (token) => ({
  type: "ADD_MS_TOKEN",
  payload: token,
});

export const removeMSToken = () => ({
  type: "REMOVE_MS_TOKEN",
});

export const setLoggedIn = (status) => ({
  type: "SET_LOGGED_IN",
  placeholder: status,
});

export const getMSToken = () => {
  return (dispatch) => {
    login().then((token) => {
      if (token !== null && typeof token !== "undefined") {
        dispatch(addMSToken(token));
        dispatch(setLoggedIn(false));
      }
    });
  };
};
*/
// ------ SMT ES actions ------

const addEsToken = (token) => ({
  type: "ADD_ES_TOKEN",
  payload: token,
});

export const fetchEsToken = () => {
  return (dispatch) => {
    getEsToken()
      .then((data) => dispatch(addEsToken(data)))
      .then(() => dispatch(fetchEsIfNeeded()));
  };
};

const setLastFetchES = (timeStamp) => ({
  type: "SET_FETCH_ES_TIME",
  payload: timeStamp,
});

const setEsLoading = (status) => ({
  type: "SET_ES_LOADING",
  payload: status,
});

export const fetchES = () => {
  return (dispatch) => {
    //console.log("fetchES");
    dispatch(fetchMakers());
    dispatch(fetchCategories());
    dispatch(fetchCompanies());
    dispatch(fetchMachines());
    dispatch(setLastFetchES(new Date()));
    dispatch(setEsLoading(false));
  };
};

export const fetchEsIfNeeded = () => {
  return (dispatch, getState) => {
    //const parsedToken = parseJwt(token);

    const date = new Date(getState().fetchedESTime);
    const currentDate = new Date();
    const secondsBetweenDates = (currentDate - date) / 1000;
    const minutesBetweenDates = Math.round(secondsBetweenDates / 60);
    //console.log("minutesBetweenDates ES: " + minutesBetweenDates);
    if (minutesBetweenDates >= 2 || isNaN(date)) {
      dispatch(fetchES());
    } else {
      dispatch(setEsLoading(false));
      console.log("not needed to fetch ES");
    }
  };
};
