import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import { areYouSure } from "../../tools";
import { sendMessage } from "../../sendMessage";
import { getCountry } from "../../countries";
import { removeCompany } from "../../../redux/companies/actions";

export const CompaniesList = ({ list = [], removeCompanyCall, history }) => {
  const [transitionStyle, setTransitionStyle] = useState("fade-out");

  const handleRemove = (e, c) => {
    e.preventDefault();
    if (
      areYouSure(
        "remove",
        c.company_name,
        "--THIS WILL ALSO REMOVE ALL MACHINES OF THE COMPANY!--"
      )
    ) {
      console.log("removed", c);
      sendMessage(`Removed "${c.company_name}"`, "success");
      removeCompanyCall(c);
    }
    if (window.location.pathname.includes("/es/companies/company")) {
      history.push("/es/companies");
    }
  };

  useEffect(() => {
    setTransitionStyle("fade-in");
  }, [list]);

  return (
    <table className={"list " + transitionStyle} data-testid="company-list">
      <thead>
        <tr>
          <th>Id</th>
          <th>Name</th>
          <th>Street</th>
          <th>Box</th>
          <th>City</th>
          <th>Postal code</th>
          <th>state</th>
          <th>Country</th>
          <th>Email</th>
          <th>Phone</th>
          <th>Mobile</th>
          <th>Options</th>
        </tr>
      </thead>
      <tbody>
        {list &&
          list.map((c, i) => {
            return (
              <tr key={i} data-testid="category">
                <td>{c.company_id}</td>
                <td>
                  <Link to={`/es/companies/company?id=${c.company_id}`}>
                    {c.company_name}
                  </Link>
                </td>
                <td>{c.street}</td>
                <td>{c.box}</td>
                <td>{c.city}</td>
                <td>{c.postal_code}</td>
                <td>{c.state}</td>
                <td>{getCountry(c.country_code)}</td>
                <td>{c.email}</td>
                <td>{c.tel1}</td>
                <td>{c.mobile}</td>

                <td>
                  <Link to={`/es/companies/edit-company?id=${c.company_id}`}>
                    Edit
                  </Link>
                  <Link
                    to="#"
                    onClick={(e) => handleRemove(e, c)}
                    className="link-space-left"
                  >
                    Remove
                  </Link>
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

const mapDispatchToProps = (dispatch) => ({
  removeCompanyCall: (company) => dispatch(removeCompany(company)),
});

export default withRouter(connect(null, mapDispatchToProps)(CompaniesList));
