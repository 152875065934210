import React from "react";

const MakerOptions = React.memo(({ makers }) => (
  <React.Fragment>
    {makers.map((m) => {
      return (
        <option key={m.maker_id} value={m.maker_id}>
          {m.Name}
        </option>
      );
    })}
  </React.Fragment>
));

/**
 * < change, currentMaker, makers />
 */
export const MakerSelect = React.memo(({ change, currentMaker, makers }) => {
  return (
    <select name="Maker_id" value={currentMaker} onChange={(e) => change(e)}>
      <option value="">Select Make</option>
      <MakerOptions makers={makers} />
    </select>
  );
});

/**
 * < change, currentMaker, makers />
 */
export const MakerSelectRequired = React.memo(
  ({ change, currentMaker, makers }) => {
    return (
      <select
        name="Maker_id"
        value={currentMaker}
        onChange={(e) => change(e)}
        required
      >
        <option value="">Select Make *</option>
        <MakerOptions makers={makers} />
      </select>
    );
  }
);
