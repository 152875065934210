import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { postMaker } from "../../../redux/makers/actions";

const AddMakerForm = ({ addMaker, makers, history }) => {
  const initMaker = {
    Name: "",
    maker_id: 0,
  };
  const [maker, setMaker] = useState(initMaker);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setMaker({ ...maker, [name]: value });
  };

  const getLastAddedMakerId = () => {
    return parseInt(makers[makers.length - 1].maker_id);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const currentMakerId = getLastAddedMakerId();
    const nextMakerId = currentMakerId + 1;
    maker.maker_id = nextMakerId.toString();
    console.log(maker);
    addMaker(maker);
    history.push("/es/makers");
  };

  return (
    <div>
      <button className="back_button" onClick={() => history.goBack()}>
        Back
      </button>
      <form onSubmit={(e) => handleSubmit(e)}>
        <h3>Add Maker</h3>
        <div className="group">
          <input
            type="text"
            name="Name"
            placeholder="Maker name"
            value={maker.name}
            onChange={(e) => handleChange(e)}
          />
        </div>

        <button type="submit">Add maker</button>
      </form>
    </div>
  );
};

const setStateToProps = (state) => ({
  makers: state.makers,
});

const setDispatchToProps = (dispatch) => ({
  addMaker: (maker) => dispatch(postMaker(maker)),
});

export default withRouter(
  connect(setStateToProps, setDispatchToProps)(AddMakerForm)
);
