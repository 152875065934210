import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import CompaniesList from "./companiesList";
import MachinesList from "../machines/machinesList";
import { sortByDate } from "../../tools";
import MembersLikeCompany from "../../mailchimp/membersLikeCompany";

const CompanyPage = ({ companies, machines }) => {
  const companyIdFromUrl = new URLSearchParams(window.location.search).get(
    "id"
  );

  const [company, setCompany] = useState(null);
  const [companyMachines, setCompanyMachines] = useState([]);
  const [companyMachinesRemoved, setCompanyMachinesRemoved] = useState([]);
  const [showRemovedMachines, setShowRemovedMachines] = useState(false);

  const MachineView = () => {
    if (
      typeof companyMachines !== "undefined" &&
      companyMachines.length > 0 &&
      showRemovedMachines === false
    ) {
      return <MachinesList list={companyMachines} />;
    } else if (
      typeof companyMachinesRemoved !== "undefined" &&
      companyMachinesRemoved.length > 0 &&
      showRemovedMachines === true
    ) {
      return <MachinesList list={companyMachinesRemoved} />;
    } else {
      return <p>This company does not have any machines</p>;
    }
  };

  const CompanyView = () => {
    if (company !== null && typeof company !== "undefined") {
      return <CompaniesList list={[company]} />; //Företaget uppdateras inte när man ändrar det
    } else {
      return <p>This company seems to be removed.</p>;
    }
  };

  const handleRemoveButton = (e) => {
    e.preventDefault();
    showRemovedMachines === false
      ? setShowRemovedMachines(true)
      : setShowRemovedMachines(false);
  };

  useEffect(() => {
    const getCompanyFromList = (id) => {
      const comp = companies.find((c) => c.company_id === id);
      comp !== "undefined" ? setCompany(comp) : setCompany(null);
    };

    const getMachinesFromCompanyId = (id) => {
      const machineList = machines.filter((m) => m.Owner_id === id);
      const sortedMachines = sortByDate([...machineList], "created_at");
      setCompanyMachinesRemoved([...sortedMachines]);
      setCompanyMachines([...sortedMachines.filter((m) => m.Removed !== true)]);
    };

    getCompanyFromList(companyIdFromUrl);
    getMachinesFromCompanyId(companyIdFromUrl);
  }, [companies, machines, companyIdFromUrl]);

  return (
    <div>
      <h2>Company</h2>
      <CompanyView />
      <div className="sub-list">
        <h2>Members from Mailchimp with same company name or email</h2>
        <MembersLikeCompany company={company} />
      </div>
      <div className="small-header">
        <h2>Company machines</h2>
        <button
          onClick={(e) => handleRemoveButton(e)}
          className={showRemovedMachines ? "button-selected" : ""}
        >
          {showRemovedMachines === false ? "Show removed" : "Hide removed"}
        </button>
      </div>
      <MachineView />
    </div>
  );
};

const setStateToProps = (state) => ({
  companies: state.companies,
  machines: state.machines,
});

export default connect(setStateToProps)(CompanyPage);
