import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import { smallScreen } from "../../tools";
import { putMachine } from "../../../redux/machines/actions";
import {
  sortByString,
  sortArray,
  sortByNumber,
  sortByDate,
  formatDate,
  sortByBoolean,
} from "../../tools";

export const MachinesList = ({
  list = [],
  makers,
  categories,
  companies,
  editMachineCall,
}) => {
  const [fixedList, setFixedList] = useState([]);
  const [sortedList, setSortedList] = useState([]);
  const [sortedKeyId, setSortedKeyId] = useState("");
  const [transitionStyle, setTransitionStyle] = useState("fade-out");

  const handleRemove = (e, m) => {
    e.preventDefault();
    let machine = { ...m };
    machine.Removed = !machine.Removed;
    //sendMessage(`${machine.Model} marked as removed`, "success");
    editMachineCall(machine);
  };

  const sort = (e, keyId, type) => {
    e.preventDefault();

    if (keyId === sortedKeyId) {
      setSortedList(fixedList.reverse());
      setSortedKeyId("");
    } else {
      let listThatIsSorted = [];
      switch (type) {
        case "int":
          listThatIsSorted = sortByNumber(fixedList, keyId);
          break;
        case "array":
          listThatIsSorted = sortArray(fixedList, keyId);
          break;
        case "date":
          listThatIsSorted = sortByDate(fixedList, keyId);
          break;
        case "boolean":
          listThatIsSorted = sortByBoolean(fixedList, keyId);
          break;
        default:
          listThatIsSorted = sortByString(fixedList, keyId);
      }
      setSortedList([...listThatIsSorted]);
      setSortedKeyId(keyId);
    }
  };

  /* saved for future
  const checkIfDoubleDigit = (number) => {
    return number > 9 ? number : "0" + number;
  };
*/

  const showDate = (dateString) => {
    if (!dateString) {
      return;
    }
    return formatDate(dateString);
  };

  const mobileView = (
    <tbody>
      {fixedList.map((m, i) => {
        return (
          <tr key={i} data-testid="machine" className="machine-row">
            <td className="row-1">{m.machine_id}</td>
            <td className="row-1">
              {m.makerName} {m.Model}
            </td>
            <td className="row-2">{m.companyName}</td>
            <td className="row-2">{m.categoryName}</td>
            <td className="row-3">Year: {m.ProductionYear}</td>
            <td className="row-3">Hours: {m.Hours}</td>
            <td className="row-3">I. Hours:{m.ImpactHours}</td>

            <td className="row-5">
              {!m.PictureLink && (
                <a
                  href={m.PictureLink}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Link
                </a>
              )}
            </td>
            <td className="row-5">Removed: {`${m.Removed}`}</td>
            <td className="row-5">Owned: {`${m.CostumerMachine}`}</td>
            <td className="row-6">
              <Link to={`/es/machines/edit-machine?id=${m.machine_id}`}>
                Edit
              </Link>
              <Link
                to="#"
                onClick={(e) => handleRemove(e, m)}
                className="link-space-left"
              >
                {m.Removed ? "Available" : "Removed"}
              </Link>
            </td>
            <td className="row-7">{m.Comments}</td>
          </tr>
        );
      })}
    </tbody>
  );

  const desktopView = (
    <tbody>
      {fixedList.map((m, i) => {
        const createdAt =
          m.OriginalFileDate === null ? m.createdAt : m.OriginalFileDate;
        return (
          <tr key={i} data-testid="machine" className="machine-row">
            <td>{m.machine_id}</td>
            <td>{m.makerName}</td>
            <td>{m.Model}</td>
            <td>
              <Link to={`/es/companies/company?id=${m.Owner_id}`}>
                {m.companyName}
              </Link>
            </td>
            <td>{m.categoryName}</td>
            <td>{m.ProductionYear}</td>
            <td>{m.Hours}</td>
            <td>{m.ImpactHours}</td>
            <td className="comment">{m.Comments}</td>
            <td>{m.Price}</td>
            <td>{showDate(createdAt)}</td>
            <td>{showDate(m.LastContact)}</td>
            <td>
              {m.PictureLink && (
                <a
                  href={m.PictureLink}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Link
                </a>
              )}
            </td>
            <td>{`${m.Removed}`}</td>
            <td>{m.CostumerMachine !== null ? `${m.CostumerMachine}` : ""}</td>
            <td>
              <Link to={`/es/machines/edit-machine?id=${m.machine_id}`}>
                Edit
              </Link>
              <Link
                to="#"
                onClick={(e) => handleRemove(e, m)}
                className="link-space"
              >
                {m.Removed ? "Available" : "Removed"}
              </Link>
            </td>
          </tr>
        );
      })}
    </tbody>
  );

  const View = () => {
    if (sortedList) {
      return smallScreen() ? mobileView : desktopView;
    }
  };

  //When list state updates set fixedList and get names
  useEffect(() => {
    const getMakerName = (makerId) => {
      if (
        typeof makerId == "string" &&
        makerId != null &&
        makerId !== "" &&
        typeof makerId !== "undefined"
      ) {
        const result = makers.find((m) => m.maker_id === makerId);
        if (typeof result === "undefined") {
          return "Maker removed!";
        }
        return result.Name;
      }
    };

    const getCategoryName = (catId) => {
      if (
        typeof catId == "string" &&
        catId != null &&
        catId !== "" &&
        typeof catId !== "undefined"
      ) {
        const result = categories.find((c) => c.cat_id === catId);
        if (typeof result === "undefined") {
          return "Category removed!";
        }
        return result.cat_name;
      }
    };

    const getCompanyName = (compId) => {
      if (
        typeof compId == "string" &&
        compId != null &&
        compId !== "" &&
        typeof compId !== "undefined"
      ) {
        const result = companies.find((c) => c.company_id === compId);
        if (typeof result === "undefined") {
          return "Company removed!";
        }
        return result.company_name;
      }
    };

    const handleList = () => {
      let newList = list.map((m) => {
        const makerName = getMakerName(m.Maker_id);
        const categoryName = getCategoryName(m.Category_id);
        const companyName = getCompanyName(m.Owner_id);
        return {
          ...m,
          makerName: makerName,
          categoryName: categoryName,
          companyName: companyName,
        };
      });

      setTransitionStyle("fade-in");

      setFixedList([...newList]);
    };

    handleList();
  }, [list, categories, companies, makers]);

  //Update if sorted
  useEffect(() => {
    if (sortedList.length !== 0 && sortedList === fixedList) {
      setFixedList([...sortedList]);
    }
  }, [fixedList, sortedList]);

  return (
    <table
      className={"list machine-list " + transitionStyle}
      data-testid="machine-list"
    >
      <thead>
        <tr>
          <th>
            <button onClick={(e) => sort(e, "machine_id", "int")}>Id</button>
          </th>
          <th>
            <button onClick={(e) => sort(e, "makerName")}>Make</button>
          </th>
          <th>
            <button onClick={(e) => sort(e, "Model")}>Model</button>
          </th>
          <th>
            <button onClick={(e) => sort(e, "companyName")}>Owner</button>
          </th>
          <th>
            <button onClick={(e) => sort(e, "categoryName")}>Category</button>
          </th>
          <th>
            <button onClick={(e) => sort(e, "ProductionYear", "int")}>
              Year
            </button>
          </th>
          <th>
            <button onClick={(e) => sort(e, "Hours", "int")}>Hours</button>
          </th>
          <th>
            <button onClick={(e) => sort(e, "ImpactHours", "int")}>
              I.hours
            </button>
          </th>
          <th>
            <button onClick={(e) => sort(e, "Comments")}>Comment</button>
          </th>
          <th>
            <button onClick={(e) => sort(e, "Price")}>Price</button>
          </th>
          <th>
            <button onClick={(e) => sort(e, "created_at", "date")}>
              Created
            </button>
          </th>
          <th>
            <button onClick={(e) => sort(e, "LastContact", "date")}>
              Last contact
            </button>
          </th>
          <th>Link</th>
          <th>
            <button onClick={(e) => sort(e, "Removed", "boolean")}>
              Removed
            </button>
          </th>
          <th>
            <button onClick={(e) => sort(e, "CostumerMachine", "boolean")}>
              Owned
            </button>
          </th>
          <th>Options</th>
        </tr>
      </thead>
      <View />
    </table>
  );
};

const mapStateToProps = (state) => ({
  makers: state.makers,
  categories: state.categories,
  companies: state.companies,
});

const mapDispatchToProps = (dispatch) => ({
  editMachineCall: (machine) => dispatch(putMachine(machine)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MachinesList)
);
