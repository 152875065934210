import React from "react";

const CategoryOptions = React.memo(({ categories }) => (
  <React.Fragment>
    {categories.map((c) => {
      return (
        <option key={c.cat_id} value={c.cat_id}>
          {c.cat_name}
        </option>
      );
    })}
  </React.Fragment>
));

/**
 * Category select without requierd attribute
 * < change, currentCategory, categories />
 */
export const CategorySelect = React.memo(
  ({ change, currentCategory, categories, req }) => {
    return (
      <select
        name="Category_id"
        value={currentCategory}
        onChange={(e) => change(e)}
      >
        <option value="">Select Category</option>
        <CategoryOptions categories={categories} />
      </select>
    );
  }
);

/**
 * Category select with requierd attribute
 * < change, currentCategory, categories />
 */
export const CategorySelectRequierd = React.memo(
  ({ change, currentCategory, categories }) => {
    return (
      <select
        name="Category_id"
        value={currentCategory}
        onChange={(e) => change(e)}
        required
      >
        <option value="">Select Category *</option>
        <CategoryOptions categories={categories} />
      </select>
    );
  }
);
