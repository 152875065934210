import { sortArray } from "../../components/tools";
import { flatState } from "../flatState";

export const companies = (state = [], action) => {
  switch (action.type) {
    case "ADD_COMPANIES":
      const flatCompanyState = flatState(action.payload);
      return sortArray(flatCompanyState, "company_id");
    case "ADD_COMPANY":
      return [
        ...state,
        { id: action.payload.data.id, ...action.payload.data.attributes },
      ];
    case "UPDATE_COMPANIES":
      const payloadIds = new Set(action.payload.map((company) => company.id));
      const filteredState = state.filter(
        (company) => !payloadIds.has(company.id)
      ); //Filters duplicates
      const flatPayload = flatState(action.payload);
      const updatedState = [...filteredState, ...flatPayload]; //Add what the filter removed
      return updatedState;
    case "EDIT_COMPANY":
      console.log(`${action.payload.company_name} company edited`);
      const newState = state.map((c) =>
        c.id === action.payload.id ? (c = { ...action.payload }) : c
      );
      return newState;
    case "DELETE_COMPANY":
      const stateWithoutRemovedCompany = state.filter(
        (c) => c.id !== action.payload.id
      );
      return stateWithoutRemovedCompany;
    default:
      return state;
  }
};

export const filteredCompanies = (state = [], action) => {
  switch (action.type) {
    case "ADD_FILTERED_COMPANY":
      return [...state, { payload: action.payload }];
    case "ADD_FILTERED_COMPANIES":
      return action.payload;

    default:
      return state;
  }
};
