import React, { useEffect } from "react";
import { connect } from "react-redux";
import MemberList from "./memberlist";
import { fetchMembersIfNeeded } from "../../redux/members/actions";

const MembersPage = ({ fetchIfNeeded, isLoading, members }) => {
  useEffect(() => {
    fetchIfNeeded();
  }, [fetchIfNeeded]);

  if (!isLoading) {
    return (
      <div>
        <div className="small-header">
          <h1>Mailchimp</h1>
          <h2>Latest members</h2>
        </div>
        <MemberList
          list={members.slice(Math.max(members.length - 10, 0)).reverse()}
        />
      </div>
    );
  } else {
    return (
      <div className="loading-text" data-testid="loading-text">
        Loading mailchimp
      </div>
    );
  }
};

const mapStateToProps = (state) => ({
  isLoading: state.isLoading,
  members: state.members,
});

const mapDispatchToProps = (dispatch) => ({
  fetchIfNeeded: (members, timeStamp) =>
    dispatch(fetchMembersIfNeeded(members, timeStamp)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MembersPage);
