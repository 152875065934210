import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { postMachine } from "../../../redux/machines/actions";
import { MakerSelectRequired } from "./makerSelect";
import { CategorySelectRequierd } from "./categorySelect";
import { CompanySelectRequired } from "./companySelect";
import { sortByNumber } from "../../tools";
import { MachineCommentShortcuts } from "./textShortcuts";

const AddMachineForm = ({
  addMachine,
  makers,
  categories,
  machines,
  companies,
  history,
}) => {
  const initMachine = {
    Maker_id: "",
    Model: "",
    LastContact: "",
    ProductionYear: "",
    Hours: "",
    ImpactHours: "",
    Kw: "",
    Bar: "",
    M3min: "",
    Comments: "",
    Price: "",
    Removed: false,
    CostumerMachine: false,
    PictureLink: "",
    Category_id: "",
    Owner_id: "",
    machine_id: "",
  };
  const [machine, setMachine] = useState(initMachine);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (name === "CostumerMachine" || name === "Removed") {
      const bool = value === "true" ? true : false;
      setMachine({ ...machine, [name]: bool });
    } else {
      setMachine({ ...machine, [name]: value });
    }
  };

  const getLastAddedMachineId = () => {
    const machinesToBeSorted = [...machines];
    const sortedList = sortByNumber(machinesToBeSorted, "machine_id");
    return sortedList[0].machine_id;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const currentMachineId = parseInt(getLastAddedMachineId());
    const nextMachineId = currentMachineId + 1;
    machine.machine_id = nextMachineId.toString();
    console.log(machine);
    addMachine(machine);
    history.push("/es/machines");
  };

  return (
    <div>
      <button className="back_button" onClick={() => history.goBack()}>
        Back
      </button>
      <form onSubmit={(e) => handleSubmit(e)}>
        <h3>Add Machine</h3>
        <div className="group">
          <div className="field">
            <CategorySelectRequierd
              change={handleChange}
              currentMaker={machine.Category_id}
              categories={categories}
            />
          </div>
          <div className="field">
            <CompanySelectRequired
              change={handleChange}
              currentCompany={machine.Owner_id}
              companies={companies}
            />
          </div>
        </div>
        <div className="group">
          <div className="field">
            <MakerSelectRequired
              change={handleChange}
              currentMaker={machine.Maker_id}
              makers={makers}
            />
          </div>
          <div className="field">
            <input
              type="text"
              name="Model"
              id="model-input"
              placeholder=""
              value={machine.Model}
              onChange={(e) => handleChange(e)}
              required
            />
            <label htmlFor="model-input">Model *</label>
          </div>
          <div className="field">
            <input
              type="text"
              name="ProductionYear"
              id="year-input"
              placeholder=""
              value={machine.ProductionYear}
              onChange={(e) => handleChange(e)}
            />
            <label htmlFor="year-input">Year</label>
          </div>
          <div className="field">
            <input
              type="text"
              name="Hours"
              id="hours-input"
              placeholder=""
              value={machine.Hours}
              onChange={(e) => handleChange(e)}
            />
            <label htmlFor="hours-input">Hours</label>
          </div>
          <div className="field">
            <input
              type="text"
              name="ImpactHours"
              id="impacthours-input"
              placeholder=""
              value={machine.ImpactHours}
              onChange={(e) => handleChange(e)}
            />
            <label htmlFor="impacthours-input">Impact hours</label>
          </div>
          <div className="field">
            <input
              type="text"
              name="Price"
              id="price-input"
              placeholder=""
              value={machine.Price}
              onChange={(e) => handleChange(e)}
            />
            <label htmlFor="price-input">Price</label>
          </div>
        </div>
        <div className="group">
          <div className="field">
            <input
              type="text"
              name="Kw"
              id="kw-input"
              placeholder=""
              value={machine.Kw}
              onChange={(e) => handleChange(e)}
            />
            <label htmlFor="kw-input">Kw</label>
          </div>
          <div className="field">
            <input
              type="text"
              name="Bar"
              id="bar-input"
              placeholder=""
              value={machine.Bar}
              onChange={(e) => handleChange(e)}
            />
            <label htmlFor="bar-input">Bar</label>
          </div>
          <div className="field">
            <input
              type="text"
              name="M3min"
              id="m3-input"
              placeholder=""
              value={machine.M3min}
              onChange={(e) => handleChange(e)}
            />
            <label htmlFor="m3-input">M3min</label>
          </div>
        </div>
        <div className="group">
          <div className="field">
            <input
              type="text"
              name="Comments"
              id="comment-input"
              placeholder=""
              value={machine.Comments}
              onChange={(e) => handleChange(e)}
            />
            <label htmlFor="comment-input">Comment</label>
            <MachineCommentShortcuts />
          </div>
          <div className="field">
            <input
              type="text"
              name="LastContact"
              id="lastcontact-input"
              placeholder=""
              value={machine.LastContact}
              onChange={(e) => handleChange(e)}
            />
            <label htmlFor="lastcontact-input">Last contact</label>
          </div>
          <div className="field">
            <select
              name="Removed"
              value={machine.Removed}
              onChange={(e) => handleChange(e)}
            >
              <option key="false" value="false">
                False
              </option>
              <option key="true" value="true">
                True
              </option>
            </select>
            <label>Removed</label>
          </div>
          <div className="field">
            <select
              name="CostumerMachine"
              value={machine.CostumerMachine}
              onChange={(e) => handleChange(e)}
            >
              <option key="false" value="false">
                False
              </option>
              <option key="true" value="true">
                True
              </option>
            </select>
            <label>Costumer Machine</label>
          </div>
        </div>
        <div className="group">
          <div className="field">
            <input
              type="text"
              name="PictureLink"
              id="picture-input"
              placeholder=""
              value={machine.PictureLink}
              onChange={(e) => handleChange(e)}
            />
            <label htmlFor="picture-input">Picture link</label>
          </div>
        </div>

        <button type="submit">Add Machine</button>
      </form>
    </div>
  );
};

const setStateToProps = (state) => ({
  makers: state.makers,
  machines: state.machines,
  categories: state.categories,
  companies: state.companies,
});

const setDispatchToProps = (dispatch) => ({
  addMachine: (machine) => dispatch(postMachine(machine)),
});

export default withRouter(
  connect(setStateToProps, setDispatchToProps)(AddMachineForm)
);
