import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { postCategory } from "../../../redux/categories/actions";

const AddCategoryForm = ({ addCategory, categories, history }) => {
  const initCategory = {
    cat_name: "",
    cat_id: 0,
  };
  const [category, setCategory] = useState(initCategory);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setCategory({ ...category, [name]: value });
  };

  const getLastAddedCategoryId = () => {
    return parseInt(categories[categories.length - 1].cat_id);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const currentCategoryId = getLastAddedCategoryId();
    const nextCategoryId = currentCategoryId + 1;
    category.cat_id = nextCategoryId.toString();
    console.log(category);
    addCategory(category);
    history.push("/es/categories");
  };

  return (
    <div>
      <button className="back_button" onClick={() => history.goBack()}>
        Back
      </button>
      <form onSubmit={(e) => handleSubmit(e)}>
        <h3>Add Category</h3>
        <div className="group">
          <input
            type="text"
            name="cat_name"
            placeholder="category name"
            value={category.cat_name}
            onChange={(e) => handleChange(e)}
          />
        </div>

        <button type="submit">Add category</button>
      </form>
    </div>
  );
};

const setStateToProps = (state) => ({
  categories: state.categories,
});

const setDispatchToProps = (dispatch) => ({
  addCategory: (category) => dispatch(postCategory(category)),
});

export default withRouter(
  connect(setStateToProps, setDispatchToProps)(AddCategoryForm)
);
