import React from "react";
import { connect } from "react-redux";
import { Route, Switch, useRouteMatch, NavLink } from "react-router-dom";

import MakerList from "./makerList";
import AddMakerForm from "./addMakerForm";
import EditMakerForm from "./editMakerForm";

const MakerPage = ({ makers, isEsLoading }) => {
  const match = useRouteMatch();

  return (
    <div>
      <Switch>
        <Route path={`${match.path}/add-maker`}>
          <AddMakerForm />
        </Route>
        <Route path={`${match.path}/edit-maker`}>
          <EditMakerForm />
        </Route>
        <React.Fragment>
          <NavLink to="/es/makers/add-maker">
            <button>Add Maker</button>
          </NavLink>
          <MakerList list={makers} />
        </React.Fragment>
      </Switch>
    </div>
  );
};

const mapStateToProps = (state) => ({
  makers: state.makers,
  isEsLoading: state.isEsLoading,
});

export default connect(mapStateToProps)(MakerPage);
