import React, { useEffect } from "react";
import { connect } from "react-redux";
import { fetchEsIfNeeded } from "../../redux/actions";

import MachinesList from "./machines/machinesList";
import CompaniesList from "./companies/companiesList";
import { sortByNumber } from "../tools";

const EsPage = ({ fetchESCall, machines, companies, isEsLoading }) => {
  const copyOfMachines = [...machines];
  const copyOfCompanies = [...companies];

  useEffect(() => {
    fetchESCall();
  }, [fetchESCall]);

  if (isEsLoading) {
    return <div className="loading-text">Loading ES</div>;
  } else {
    return (
      <div>
        <h1>ES</h1>
        <h3>Latest machines</h3>
        {copyOfMachines.length > 0 ? (
          <MachinesList
            list={sortByNumber([...machines], "machine_id").slice(0, 5)}
          />
        ) : (
          <div className="loading-text-10">Starting the machines...</div>
        )}

        <h3>Latest companies</h3>
        {copyOfCompanies.length > 0 ? (
          <CompaniesList
            list={sortByNumber([...copyOfCompanies], "company_id").slice(0, 5)}
          />
        ) : (
          <div className="loading-text-10">Setting up companies...</div>
        )}
      </div>
    );
  }
};

const mapStateToProps = (state) => ({
  isEsLoading: state.isEsLoading,
  machines: state.machines,
  companies: state.companies,
});

const mapDispatchToProps = (dispatch) => ({
  fetchESCall: () => dispatch(fetchEsIfNeeded()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EsPage);
