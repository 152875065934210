import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { getCountry } from "../countries";
import { removeDot } from "../tools";

const MemberList = ({ list }) => {
  const [editedList, setEditedList] = useState(list);
  const [transitionStyle, setTransitionStyle] = useState("fade-out");
  //console.log(list);
  //console.log(editedList);
  const displayTags = (tags) => {
    if (tags.length <= 1 && typeof tags[0] !== "undefined") {
      return tags[0].status !== "inactive" ? tags[0].name : null;
    } else {
      let newTagString = "";

      for (let i = 0; i < tags.length; i++) {
        if (tags[i].status !== "inactive") {
          if (
            typeof tags[i + 1] !== "undefined" &&
            tags[i + 1].status === "inactive"
          ) {
            newTagString = newTagString + tags[i].name;
          } else if (i === tags.length - 1) {
            newTagString = newTagString + tags[i].name;
          } else {
            newTagString = newTagString + tags[i].name + ", ";
          }
        }
      }

      return newTagString;
    }
  };

  useEffect(() => {
    const newList = list.map((m) => {
      return {
        ...m,
        merge_fields: {
          FNAME: removeDot(m.merge_fields.FNAME),
          LNAME: removeDot(m.merge_fields.LNAME),
          ADDRESS: {
            addr1: removeDot(m.merge_fields.ADDRESS.addr1, ", "),
            addr2: removeDot(m.merge_fields.ADDRESS.addr2, ", "),
            state: removeDot(m.merge_fields.ADDRESS.state, ", "),
            zip: removeDot(m.merge_fields.ADDRESS.zip, " "),
            city: removeDot(m.merge_fields.ADDRESS.city),
            country: getCountry(m.merge_fields.ADDRESS.country),
          },
          PHONE: m.merge_fields.PHONE,
          COMPANY: m.merge_fields.COMPANY,
        },
      };
    });
    setTransitionStyle("fade-in");
    setEditedList(newList);
  }, [list]);

  return (
    <table className={"list " + transitionStyle} data-testid="member-list">
      <thead>
        <tr>
          <th>Company</th>
          <th>Name</th>
          <th>Email</th>
          <th>Phone</th>
          <th>Address</th>
          <th>Country</th>
          <th>Status</th>
          <th>Tags</th>
          <th>Options</th>
        </tr>
      </thead>
      <tbody>
        {editedList.map((m, i) => {
          return (
            <tr className="member" key={i} data-testid="member">
              <td className="company_name">
                <strong data-testid="member_company_name">
                  {m.merge_fields.COMPANY}
                </strong>
              </td>
              <td className="name">
                {m.merge_fields.FNAME + " " + m.merge_fields.LNAME}
              </td>
              <td className="email">
                <a
                  href={"mailto:" + m.email_address}
                  data-testid="member_email"
                >
                  {m.email_address}
                </a>
              </td>

              <td className="phone">
                <a href={"tel:" + m.merge_fields.PHONE}>
                  {m.merge_fields.PHONE}
                </a>
              </td>
              <td className="address">
                {m.merge_fields.ADDRESS.addr1}
                {m.merge_fields.ADDRESS.addr2}
                {m.merge_fields.ADDRESS.state}
                {m.merge_fields.ADDRESS.zip}
                {m.merge_fields.ADDRESS.city}
              </td>
              <td className="country">{m.merge_fields.ADDRESS.country}</td>

              <td className="status">{m.status}</td>
              <td className="tags">{displayTags(m.tags)}</td>
              <td>
                <Link to={`/mailchimp/edit-member?id=${m.id}`}>Edit</Link>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default withRouter(MemberList);
