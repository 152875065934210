import React from "react";
import MemberList from "./memberlist";
import { connect } from "react-redux";

const ShowAllMembers = ({ members }) => (
  <div>
    <h2>All members</h2>
    <MemberList list={members} />
  </div>
);

const addStateToProps = (state) => ({
  members: state.members,
});

export default connect(addStateToProps)(ShowAllMembers);
