import React from "react";
import MemberList from "./memberlist";
import { connect } from "react-redux";

const SearchResults = ({ filteredMembers, isLoading }) => {
  let result;
  if (isLoading === true) {
    result = <div className="loading-text">Loading</div>;
  } else if (filteredMembers.length > 0) {
    result = <MemberList list={filteredMembers} />;
  } else {
    result = <div className="loading-text">No matches</div>;
  }

  return (
    <div>
      <h2>Search result</h2>
      {result}
    </div>
  );
};

const addStateToProps = (state) => ({
  filteredMembers: state.filteredMembers,
  isLoading: state.isLoading,
});

export default connect(addStateToProps)(SearchResults);
