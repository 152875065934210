import React from "react";
import { connect } from "react-redux";
import { Route, Switch, useRouteMatch, NavLink } from "react-router-dom";

import CategoriesList from "./categoriesList";
import AddCategoryForm from "./addCategoryForm";
import EditCategoryForm from "./editCategoryForm";

const MakerPage = ({ categories, isEsLoading }) => {
  const match = useRouteMatch();

  const ButtonAndListCombo = () => {
    return (
      <div>
        <NavLink to="/es/categories/add-category">
          <button>Add Category</button>
        </NavLink>
        <CategoriesList list={categories} />
      </div>
    );
  };

  return (
    <div>
      <Switch>
        <Route path={`${match.path}/add-category`}>
          <AddCategoryForm />
        </Route>
        <Route path={`${match.path}/edit-category`}>
          <EditCategoryForm />
        </Route>
        <ButtonAndListCombo />
      </Switch>
    </div>
  );
};

const mapStateToProps = (state) => ({
  categories: state.categories,
  isEsLoading: state.isEsLoading,
});

export default connect(mapStateToProps)(MakerPage);
