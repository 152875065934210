import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter, useRouteMatch } from "react-router-dom";
import { removeMaker } from "../../../redux/makers/actions";
import { areYouSure } from "../../tools";
import { sendMessage } from "../../sendMessage";

export const MakerList = ({ list = [], removeMakerCall }) => {
  const match = useRouteMatch();
  const [transitionStyle, setTransitionStyle] = useState("fade-out");

  const sortByMakerId = (listToBeSorted) => {
    let listLength = listToBeSorted.length;
    for (let i = 0; i < listLength; i++) {
      for (let j = 0; j < listLength; j++) {
        if (listToBeSorted[j + 1]) {
          if (
            parseInt(listToBeSorted[j].maker_id) >
            parseInt(listToBeSorted[j + 1].maker_id)
          ) {
            let tempObj = listToBeSorted[j + 1];
            listToBeSorted[j + 1] = listToBeSorted[j];
            listToBeSorted[j] = tempObj;
          }
        }
      }
    }
    return listToBeSorted;
  };

  const handleRemove = (e, m) => {
    e.preventDefault();
    if (areYouSure("remove", m.Name)) {
      console.log("removed", m);
      sendMessage(`Removed "${m.Name}"`, "success");
      removeMakerCall(m);
    }
  };

  let sortedList = sortByMakerId(list);
  useEffect(() => {
    setTransitionStyle("fade-in");
  }, [list]);
  return (
    <table className={"list " + transitionStyle} data-testid="maker-list">
      <thead>
        <tr>
          <th>Id</th>
          <th>Name</th>
          <th>Options</th>
        </tr>
      </thead>
      <tbody>
        {sortedList.map((m, i) => {
          return (
            <tr className="member" key={i} data-testid="maker">
              <td>{m.maker_id}</td>
              <td>{m.Name}</td>
              <td>
                <Link to={`${match.url}/edit-maker?id=${m.maker_id}`}>
                  Edit
                </Link>
                <Link
                  to="#"
                  onClick={(e) => handleRemove(e, m)}
                  className="link-space-left"
                >
                  Remove
                </Link>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const setDispatchToProps = (dispatch) => ({
  removeMakerCall: (m) => dispatch(removeMaker(m)),
});

export default withRouter(connect(null, setDispatchToProps)(MakerList));
