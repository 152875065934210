import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { putMaker } from "../../../redux/makers/actions";

const EditMakerForm = ({ editMaker, makers, history }) => {
  const makerId = new URLSearchParams(window.location.search).get("id");

  const initMaker = {
    Name: "",
    maker_id: 0,
  };
  const [maker, setMaker] = useState(initMaker);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setMaker({ ...maker, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    editMaker(maker);
    setMaker(initMaker);
    history.push("/es/makers");
  };

  useEffect(() => {
    const makerFromId = makers.find((m) => m.maker_id === makerId);
    setMaker(makerFromId);
  }, [makerId, makers]);

  return (
    <div>
      <button className="back_button" onClick={() => history.goBack()}>
        Back
      </button>
      <form onSubmit={(e) => handleSubmit(e)}>
        <h3>Add Maker</h3>
        <div className="group">
          <input
            type="text"
            name="Name"
            placeholder="Maker name"
            value={maker.Name}
            onChange={(e) => handleChange(e)}
          />
        </div>

        <button type="submit">Edit maker</button>
      </form>
    </div>
  );
};

const setStateToProps = (state) => ({
  makers: state.makers,
});

const setDispatchToProps = (dispatch) => ({
  editMaker: (maker) => dispatch(putMaker(maker)),
});

export default withRouter(
  connect(setStateToProps, setDispatchToProps)(EditMakerForm)
);
