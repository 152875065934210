import {
  getCategories,
  postToES,
  putToES,
  deleteToES,
  getLastUpdated,
} from "../../apiES";
import { sortByDate } from "../../components/tools";
import { countAndCompareType } from "../countAndCompareType";

const addCategories = (categories) => ({
  type: "ADD_CATEGORIES",
  payload: categories,
});

export const fetchAllCategories = () => {
  return (dispatch) => {
    getCategories().then((data) => {
      if (typeof data !== "undefined") {
        dispatch(addCategories(data));
      }
    });
  };
};
/* Replaced by fetchUpdatedCategories

export const fetchLatestCategories = () => {
  return (dispatch, getState) => {
    const categories = [...getState().categories];
    const latestAddedCategories = sortByDate([...categories], "created_at");
    getLatest(
      "categories",
      getState().esToken,
      latestAddedCategories[0].id
    ).then((data) => {
      if (typeof data !== "undefined") {
        dispatch(updateCategory(data));
      }
    });
  };
};
*/

const fetchUpdatedCategories = () => {
  return (dispatch, getState) => {
    const categories = [...getState().categories];
    const latestCategoriesUpdated = sortByDate([...categories], "updatedAt");
    getLastUpdated("categories", latestCategoriesUpdated[0].updatedAt).then(
      (data) => {
        if (typeof data !== "undefined" && data.length !== 0) {
          dispatch(updateCategory(data));
        }
      }
    );
  };
};

const addCategory = (category) => ({
  type: "ADD_CATEGORY",
  payload: category,
});

const updateCategory = (categories) => ({
  type: "UPDATE_CATEGORIES",
  payload: categories,
});

export const fetchCategories = () => {
  return async (dispatch, getState) => {
    const categoriesLength = getState().categories.length;
    const inSyncWithApi = await countAndCompareType(
      "categories",
      categoriesLength
    );

    if (categoriesLength > 0 && inSyncWithApi) {
      dispatch(fetchUpdatedCategories());
    } else {
      dispatch(fetchAllCategories());
    }
  };
};

export const postCategory = (category) => {
  return (dispatch) => {
    postToES("categories", category)
      .then((data) => dispatch(addCategory(data)))
      .catch((error) => console.log(error));
  };
};

const editCategory = (category) => ({
  type: "EDIT_CATEGORY",
  payload: category,
});

export const putCategory = (category) => {
  return (dispatch) => {
    putToES("categories", category)
      .then(() => dispatch(editCategory(category)))
      .catch((error) => console.log(error));
  };
};

const deleteCategory = (category) => ({
  type: "DELETE_CATEGORY",
  payload: category,
});

export const removeCategory = (category) => {
  return (dispatch) => {
    deleteToES("categories", category)
      .then(() => dispatch(deleteCategory(category)))
      .catch((error) => console.log(error));
  };
};
