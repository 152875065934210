import React, { useState, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { addFilteredCompanies } from "../../../redux/companies/actions";
import { connect } from "react-redux";
import { countries } from "../../countries";

const CompanySearch = ({ companies, addFilteredCompanies }) => {
  const history = useHistory();
  let match = useRouteMatch();
  const initSearchTerms = {
    id: "",
    name: "",
    email: "",
    street: "",
    city: "",
    postal_code: "",
    phone: "",
    country: "0",
    mobile: "",
  };
  const [searchTerms, setSearchTerms] = useState(initSearchTerms);
  const [changeing, setChangeing] = useState(false);
  const [searching, setSearching] = useState(false);

  let timer = null;

  const handleSearch = async (e) => {
    e.preventDefault();
    history.push(`${match.path}/search-results`);
  };

  const handleClear = () => {
    setSearching(false);
    setSearchTerms(initSearchTerms);
  };

  /**
   * Waits for input to stop and then updates searchTerms with target name and value
   *
   * @param {object} e Input event object
   * @return {object} Updated searchTerms with targeted name and value
   */
  const handleChange = (e) => {
    const { name, value } = e.target;
    setChangeing(true);
    clearTimeout(timer);
    timer = setTimeout(() => {
      setChangeing(false);
    }, 600);

    setSearchTerms({ ...searchTerms, [name]: value });
  };

  const filterCompanies = (companiesToBeFiltered) => {
    const scoredCompanies = companiesToBeFiltered.map((company) => {
      let companyScore = 0;

      if (searchTerms.id.length > 0 && company.company_id === searchTerms.id) {
        companyScore += 1;
      }
      if (searchTerms.name.length > 0) {
        if (
          company.company_name.search(
            new RegExp(searchTerms.name.trim(), "gi")
          ) >= 0
        ) {
          companyScore += 1;
        }
      }

      if (company.email && searchTerms.email.length > 0) {
        if (
          company.email.search(new RegExp(searchTerms.email.trim(), "gi")) >= 0
        ) {
          companyScore += 1;
        }
      }
      if (
        company.street &&
        searchTerms.street.length > 0 &&
        company.street.search(new RegExp(searchTerms.street.trim(), "gi")) >= 0
      ) {
        companyScore += 1;
      }
      if (
        company.city &&
        searchTerms.city.length > 0 &&
        company.city.search(new RegExp(searchTerms.city.trim(), "gi")) >= 0
      ) {
        companyScore += 1;
      }
      if (
        searchTerms.postal_code.length > 0 &&
        company.postal_code.search(
          new RegExp(searchTerms.postal_code.trim(), "gi")
        ) >= 0
      ) {
        companyScore += 1;
      }

      if (searchTerms.country !== "0") {
        if (searchTerms.country === company.country_code) {
          companyScore += 1;
        }
      }

      if (
        company.tel1 &&
        searchTerms.phone.length > 0 &&
        company.tel1.search(new RegExp(searchTerms.phone.trim(), "gi")) >= 0
      ) {
        companyScore += 1;
      }
      if (
        company.mobile &&
        searchTerms.mobile.length > 0 &&
        company.mobile.search(new RegExp(searchTerms.mobile.trim(), "gi")) >= 0
      ) {
        companyScore += 1;
      }

      const companyWithScore = { ...company, score: companyScore };
      return companyWithScore;
    });

    scoredCompanies.sort((a, b) => a.score - b.score).reverse();

    if (scoredCompanies[0].score !== 0) {
      const filteredCompanies = scoredCompanies.filter(
        (m) => scoredCompanies[0].score === m.score
      );
      addFilteredCompanies(filteredCompanies);
    } else {
      addFilteredCompanies([]);
    }
  };

  const isNotSearching = () => {
    setSearching(false);
    console.log("is not searching");
  };
  const isSearching = () => {
    setSearching(true);
    console.log("is searching");
  };

  //Update filtered companies
  useEffect(() => {
    if (!changeing) {
      if (
        searchTerms.id.length <= 0 &&
        searchTerms.name.length <= 1 &&
        searchTerms.email.length <= 1 &&
        searchTerms.street.length <= 0 &&
        searchTerms.city.length <= 0 &&
        searchTerms.postal_code.length <= 0 &&
        searchTerms.phone.length <= 0 &&
        searchTerms.country.length <= 1 &&
        searchTerms.mobile.length <= 0
      ) {
        isNotSearching();
        //addFilteredCompanies(companies); //Display all companies if search is empty
      } else {
        isSearching();
        filterCompanies(companies); //Dont know if using function in useEffect is wrong, but there is no JEST message telling me wrong so i will keep it for now :D
      }
    }
  });

  return (
    <form
      className="search-form company"
      onSubmit={(e) => {
        handleSearch(e);
      }}
    >
      <input
        type="text"
        placeholder="Id"
        onChange={(e) => handleChange(e)}
        value={searchTerms.id}
        name="id"
      />
      <input
        type="text"
        placeholder="Company name"
        onChange={(e) => handleChange(e)}
        value={searchTerms.name}
        name="name"
      />
      <input
        type="text"
        placeholder="Email"
        onChange={(e) => handleChange(e)}
        value={searchTerms.email}
        name="email"
      />
      <input
        type="text"
        placeholder="street"
        onChange={(e) => handleChange(e)}
        value={searchTerms.street}
        name="street"
      />
      <input
        type="text"
        placeholder="City"
        onChange={(e) => handleChange(e)}
        value={searchTerms.city}
        name="city"
      />

      <select
        name="country"
        value={searchTerms.country}
        onChange={(e) => handleChange(e)}
      >
        <option value="0">Select Country</option>
        {countries.map((c) => {
          return (
            <option key={c.code} value={c.code}>
              {c.name}
            </option>
          );
        })}
      </select>
      <input
        type="text"
        placeholder="Phone"
        onChange={(e) => handleChange(e)}
        value={searchTerms.phone}
        name="phone"
      />
      <input
        type="text"
        placeholder="Mobile"
        onChange={(e) => handleChange(e)}
        value={searchTerms.mobile}
        name="mobile"
      />
      <button type="submit" className="search-button">
        Search
      </button>
      {searching && (
        <button className="outlined float-right" onClick={() => handleClear()}>
          Clear
        </button>
      )}
    </form>
  );
};

const addStateToProps = (state) => ({
  companies: state.companies,
});

const addDispatchToProps = (dispatch) => ({
  addFilteredCompanies: (companies) =>
    dispatch(addFilteredCompanies(companies)),
});

export default connect(addStateToProps, addDispatchToProps)(CompanySearch);
