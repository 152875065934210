import {
  getMakers,
  postToES,
  putToES,
  deleteToES,
  getLastUpdated,
} from "../../apiES";
import { sortByDate } from "../../components/tools";
import { countAndCompareType } from "../countAndCompareType";

const addMakers = (makers) => ({
  type: "ADD_MAKERS",
  payload: makers,
});

const fetchAllMakers = () => {
  return (dispatch) => {
    getMakers().then((data) => {
      if (typeof data !== "undefined") {
        dispatch(addMakers(data));
      }
    });
  };
};

const updateMakers = (makers) => ({
  type: "UPDATE_MAKERS",
  payload: makers,
});

/* Replaced by fetchUpdatedMakers

export const fetchLatestMakers = () => {
  return (dispatch, getState) => {
    const makers = [...getState().makers];
    const latestAddedMaker = sortByDate([...makers], "created_at");
    getLatest("makers", getState().esToken, latestAddedMaker[0].id).then(
      (data) => {
        if (typeof data !== "undefined") {
          dispatch(updateMakers(data));
        }
      }
    );
  };
};
*/

const fetchUpdatedMakers = () => {
  return (dispatch, getState) => {
    const makers = [...getState().makers];
    const latestUpdatedMaker = sortByDate([...makers], "updatedAt");
    getLastUpdated("makers", latestUpdatedMaker[0].updatedAt).then((data) => {
      if (typeof data !== "undefined" && data.length !== 0) {
        dispatch(updateMakers(data));
      }
    });
  };
};

export const fetchMakers = () => {
  return async (dispatch, getState) => {
    const makerLength = getState().makers.length;
    const inSyncWithApi = await countAndCompareType("makers", makerLength);

    if (makerLength > 0 && inSyncWithApi) {
      dispatch(fetchUpdatedMakers());
    } else {
      dispatch(fetchAllMakers());
    }
  };
};

const addMaker = (maker) => ({
  type: "ADD_MAKER",
  payload: maker,
});

export const postMaker = (maker) => {
  return (dispatch) => {
    postToES("makers", maker)
      .then((data) => dispatch(addMaker(data)))
      .catch((error) => console.log(error));
  };
};

const editMaker = (maker) => ({
  type: "EDIT_MAKER",
  payload: maker,
});

export const putMaker = (maker) => {
  return (dispatch) => {
    putToES("makers", maker)
      .then(() => dispatch(editMaker(maker)))
      .catch((error) => console.log(error));
  };
};

const deleteMaker = (maker) => ({
  type: "DELETE_MAKER",
  payload: maker,
});

export const removeMaker = (maker) => {
  return (dispatch) => {
    deleteToES("makers", maker)
      .then(() => dispatch(deleteMaker(maker)))
      .catch((err) => console.log(err));
  };
};
