import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter, useRouteMatch } from "react-router-dom";

import { areYouSure } from "../../tools";
import { sendMessage } from "../../sendMessage";
import { removeCategory } from "../../../redux/categories/actions";

export const MakerList = ({ list = [], removeCategoryCall }) => {
  const match = useRouteMatch();
  const [transitionStyle, setTransitionStyle] = useState("fade-out");

  const sortByCategoryId = (l) => {
    let listToBeSorted = l;
    let listLength = listToBeSorted.length;
    for (let i = 0; i < listLength; i++) {
      for (let j = 0; j < listLength; j++) {
        if (listToBeSorted[j + 1]) {
          if (
            parseInt(listToBeSorted[j].cat_id) >
            parseInt(listToBeSorted[j + 1].cat_id)
          ) {
            let tempObj = listToBeSorted[j + 1];
            listToBeSorted[j + 1] = listToBeSorted[j];
            listToBeSorted[j] = tempObj;
          }
        }
      }
    }
    return listToBeSorted;
  };

  const handleRemove = (e, c) => {
    e.preventDefault();
    if (areYouSure("remove", c.cat_name)) {
      console.log("removed", c);
      sendMessage(`Removed "${c.cat_name}"`, "success");
      removeCategoryCall(c);
    }
  };

  let sortedList = sortByCategoryId(list);

  useEffect(() => {
    setTransitionStyle("fade-in");
  }, [list]);

  return (
    <table className={"list " + transitionStyle} data-testid="category-list">
      <thead>
        <tr>
          <th>Id</th>
          <th>Name</th>
          <th>Options</th>
        </tr>
      </thead>
      <tbody>
        {sortedList.map((c, i) => {
          return (
            <tr key={i} data-testid="category">
              <td>{c.cat_id}</td>
              <td>{c.cat_name}</td>
              <td>
                <Link to={`${match.url}/edit-category?id=${c.cat_id}`}>
                  Edit
                </Link>
                <Link
                  to="#"
                  onClick={(e) => handleRemove(e, c)}
                  className="link-space-left"
                >
                  Remove
                </Link>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const setDispatchToProps = (dispatch) => ({
  removeCategoryCall: (category) => dispatch(removeCategory(category)),
});

export default withRouter(connect(null, setDispatchToProps)(MakerList));
