import React from "react";

/**
 * Inputs the button text into the Input field.
 * Requiers input with id "comment-input".
 * @returns inputs text into input field
 */
export const MachineCommentShortcuts = () => {
  const handleClick = (e) => {
    let input = document.getElementById("comment-input");

    if (input.value.length > 0) {
      // This will work by calling the native setter bypassing Reacts incorrect value change check
      Object.getOwnPropertyDescriptor(
        window.HTMLInputElement.prototype,
        "value"
      ).set.call(input, input.value + ", " + e.target.innerText);
    } else {
      Object.getOwnPropertyDescriptor(
        window.HTMLInputElement.prototype,
        "value"
      ).set.call(input, e.target.innerText);
    }

    // This will trigger a new render wor the component
    input.dispatchEvent(new Event("change", { bubbles: true }));
  };

  return (
    <div className="shortcuts">
      <button type="button" onClick={(e) => handleClick(e)}>
        PROT
      </button>
      <button type="button" onClick={(e) => handleClick(e)}>
        FOTO
      </button>
      <button type="button" onClick={(e) => handleClick(e)}>
        FILM
      </button>
      <button type="button" onClick={(e) => handleClick(e)}>
        S/N:
      </button>
    </div>
  );
};
