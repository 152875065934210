import React from "react";
import { withMsal } from "@azure/msal-react";

const LogoutButton = (props) => {
  const msalInstance = props.msalContext.instance;

  const handleLogout = () => {
    props.resetState();
    sessionStorage.clear();
    msalInstance.logout();
    //logout();
  };

  return (
    <button className="danger float-right" onClick={() => handleLogout()}>
      Logout
    </button>
  );
};

export default withMsal(LogoutButton);
