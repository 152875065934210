import React, { useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import Search from "./search";

import { connect } from "react-redux";
import { fetchMembersIfNeeded } from "../../redux/members/actions";

import SearchResults from "./searchresults";

import AddMemberForm from "./addMemberForm";
import EditMemberForm from "./editMemberForm";
import MembersPage from "./membersPage";

const MailchimpApplicationPage = ({ fetchIfNeeded }) => {
  let match = useRouteMatch();

  useEffect(() => {
    fetchIfNeeded();
  }, [fetchIfNeeded]);
  return (
    <div>
      <div className="header">
        <Search />
      </div>
      <div className="content">
        <Switch>
          <Route path={`${match.path}/edit-member`}>
            <EditMemberForm />
          </Route>
          <Route path={`${match.path}/add-member`}>
            <AddMemberForm />
          </Route>
          <Route path={`${match.path}/search-results`}>
            <SearchResults />
          </Route>
          <Route path={`${match.path}`}>
            <MembersPage />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetchIfNeeded: (members, timeStamp) =>
    dispatch(fetchMembersIfNeeded(members, timeStamp)),
});

export default connect(null, mapDispatchToProps)(MailchimpApplicationPage);
