import { flatState } from "../flatState";

export const categories = (state = [], action) => {
  switch (action.type) {
    case "ADD_CATEGORIES":
      /*if (typeof action.payload !== "undefined") {
        return (state = action.payload);
      }*/

      return flatState(action.payload);
    case "ADD_CATEGORY":
      return [
        ...state,
        { id: action.payload.data.id, ...action.payload.data.attributes },
      ];
    case "UPDATE_CATEGORIES":
      const payloadIds = new Set(action.payload.map((category) => category.id));
      const filteredState = state.filter(
        (category) => !payloadIds.has(category.id)
      ); //Filters duplicates
      const flatPayload = flatState(action.payload);
      const updatedState = [...filteredState, ...flatPayload]; //Add what  the filter removed
      return updatedState;
    case "EDIT_CATEGORY":
      console.log(`${action.payload.Name} category edited`);
      const newState = state.map((c) =>
        c.id === action.payload.id ? (c = { ...action.payload }) : c
      );
      return newState;
    case "DELETE_CATEGORY":
      const stateWithoutRemovedMaker = state.filter(
        (c) => c.id !== action.payload.id
      );
      return stateWithoutRemovedMaker;
    default:
      return state;
  }
};
