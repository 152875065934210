import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { putCompany } from "../../../redux/companies/actions";
import { countries } from "../../countries";

const EditCompanyForm = ({ editCompany, companies, history }) => {
  const companyId = new URLSearchParams(window.location.search).get("id");

  const initCompany = {
    company_name: "",
    street: "",
    box: "",
    postal_code: "",
    state: "",
    city: "",
    tel1: "",
    mobile: "",
    email: "",
    country_code: "",
    company_id: "",
  };
  const [company, setCompany] = useState(initCompany);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setCompany({ ...company, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    editCompany(company);
    history.goBack();
  };

  useEffect(() => {
    setCompany(companies.find((c) => c.company_id === companyId));
  }, [companyId, companies]);

  return (
    <div>
      <button className="back_button" onClick={() => history.goBack()}>
        Back
      </button>
      <form onSubmit={(e) => handleSubmit(e)}>
        <h3>Add company</h3>
        <div className="group">
          <div className="field">
            <input
              required
              type="text"
              name="company_name"
              value={company.company_name}
              onChange={(e) => handleChange(e)}
            />
            <label>Company name *</label>
          </div>
        </div>
        <div className="group">
          <div className="field">
            <input
              type="text"
              name="street"
              value={company.street}
              onChange={(e) => handleChange(e)}
            />
            <label>Street name</label>
          </div>
          <div className="field">
            <input
              type="text"
              name="box"
              value={company.box}
              onChange={(e) => handleChange(e)}
            />
            <label>Box</label>
          </div>
          <div className="field">
            <input
              type="text"
              name="state"
              value={company.state}
              onChange={(e) => handleChange(e)}
            />
            <label>State</label>
          </div>
          <div className="field">
            <input
              type="text"
              name="postal_code"
              value={company.postal_code}
              onChange={(e) => handleChange(e)}
            />
            <label>Postal code</label>
          </div>
          <div className="field">
            <input
              type="text"
              name="city"
              value={company.city}
              onChange={(e) => handleChange(e)}
            />
            <label>City</label>
          </div>
          <div className="field">
            <select
              name="country_code"
              value={company.country_code}
              onChange={(e) => handleChange(e)}
              required
            >
              <option value="">Choose country</option>
              {countries.map((c) => {
                return (
                  <option key={c.code} value={c.code}>
                    {c.name}
                  </option>
                );
              })}
            </select>
            <label>Country *</label>
          </div>
        </div>
        <div className="group">
          <div className="field">
            <input
              type="text"
              name="tel1"
              value={company.tel1}
              onChange={(e) => handleChange(e)}
            />
            <label>Phone</label>
          </div>
          <div className="field">
            <input
              type="text"
              name="mobile"
              value={company.mobile}
              onChange={(e) => handleChange(e)}
            />
            <label>Mobile</label>
          </div>
          <div className="field">
            <input
              type="text"
              name="email"
              value={company.email}
              onChange={(e) => handleChange(e)}
            />
            <label>Email</label>
          </div>
        </div>

        <button type="submit">Edit company</button>
      </form>
    </div>
  );
};

const setStateToProps = (state) => ({
  companies: state.companies,
});

const setDispatchToProps = (dispatch) => ({
  editCompany: (company) => dispatch(putCompany(company)),
});

export default withRouter(
  connect(setStateToProps, setDispatchToProps)(EditCompanyForm)
);
