/**
 *
 * @param {String} message Free text message
 * @param {String} status success / fail
 */
export const sendMessage = (message, status) => {
  console.log(message + " " + status);
  const messageBar = document.getElementById("message");
  messageBar.getElementsByTagName("span")[0].innerHTML = message;
  messageBar.style.display = "block";
  switch (status) {
    case "success":
      messageBar.style.backgroundColor = "var(--confirm)";
      break;
    case "fail":
      messageBar.style.backgroundColor = "var(--warning)";
      break;
    default:
      console.log("no status");
  }
  messageBar.style.animation = "drop-down 1s forwards";
  setTimeout(() => {
    messageBar.style.animation = "drop-up 1s forwards";
  }, 8000);
};

export const closeMessage = () => {
  const messageBar = document.getElementById("message");
  messageBar.style.display = "none";
};
