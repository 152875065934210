import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { sortArray } from "../../tools";

import CompaniesList from "./companiesList";
import CompanySearch from "./companySearch";
import AddCompanyForm from "./addCompanyForm";
import EditCompanyForm from "./editCompanyForm";
import CompaniesSearchResult from "./companiesSearchResult";
import CompanyPage from "./companyPage";
import { sortByNumber, sortByDate } from "../../tools";

const CompaniesPage = ({ companies }) => {
  const match = useRouteMatch();
  const [companiesList, setCompaniesList] = useState([]);

  useEffect(() => {
    const lastAddedCompanies = sortByDate([...companies], "createdAt").slice(
      0,
      30
    );
    setCompaniesList(sortByNumber(lastAddedCompanies, "company_id"));
  }, [companies]);

  return (
    <div>
      <div className="header">
        <CompanySearch />
      </div>
      <div className="content">
        <Switch>
          <Route path={`${match.path}/add-company`}>
            <AddCompanyForm />
          </Route>
          <Route path={`${match.path}/edit-company`}>
            <EditCompanyForm />
          </Route>
          <Route path={`${match.path}/search-results`}>
            <CompaniesSearchResult />
          </Route>
          <Route path={`${match.path}/company`}>
            <CompanyPage />
          </Route>
          <CompaniesList list={companiesList} />
        </Switch>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  companies: sortArray(state.companies, "company_id"),
});

export default connect(mapStateToProps)(CompaniesPage);
