import {
  getMembers,
  getListStats,
  postMember,
  putMember,
  deleteMember,
  postTags,
} from "../../api";

import { setLoading } from "../actions";

export const addMember = (member) => ({
  type: "ADD_MEMBER",
  payload: member,
});

const editMember = (member) => ({
  type: "EDIT_MEMBER",
  payload: member,
});

const removeMember = (memberId) => ({
  type: "REMOVE_MEMBER",
  payload: memberId,
});

export const editApiAndReduxMember = (member) => {
  return (dispatch) => {
    putMember(member);
    postTags(member);
    dispatch(editMember(member));
  };
};

export const createAndRemoveApiAndReduxMember = (member, oldMemberId) => {
  return (dispatch) => {
    deleteMember(oldMemberId)
      .then(dispatch(removeMember(oldMemberId)))
      .then(dispatch(postAndgetMembers(member)));
  };
};

export const postAndgetMembers = (member) => {
  return (dispatch) => {
    postMember(member)
      .then((resp) => resp.status === 200 ? dispatch(addMember(member)) : null) 
      .then(() => dispatch(fetchMembers()));
  };
};

const setLastFetchMembers = (timeStamp) => ({
  type: "SET_FETCH_MEMBERS_TIME",
  payload: timeStamp,
});

export const fetchMembersIfNeeded = () => {
  return (dispatch, getState) => {
    const date = new Date(getState().fetchedMembersTime);
    const currentDate = new Date();
    const secondsBetweenDates = (currentDate - date) / 1000;
    const minutesBetweenDates = Math.round(secondsBetweenDates / 60);
    console.log("minutesBetweenDates Chimp: " + minutesBetweenDates);
    if (getState().members <= 0 || minutesBetweenDates >= 5) {
      console.log("fetchMembers");
      dispatch(fetchMembers());
    } else {
      setLoading(false);
      console.log("not needed");
    }
  };
};

const reciveMembers = (members) => ({
  type: "RECIVE_MEMBERS",
  payload: members,
});

export const fetchMembers = () => {
  return (dispatch) => {
    console.log("fetchMembers");
    fetchAllApiMembers()
      .then((data) => dispatch(reciveMembers(data)))
      .then((data) => dispatch(screenForTags(data.payload)))
      .then(() => dispatch(setLoading(false)));
    dispatch(setLastFetchMembers(new Date()));
  };
};

const fetchAllApiMembers = async () => {
  console.log("fetchAllApiMembers");
  let offset = 0;
  let members = [];
  const apiCalls = await memberCount();
  for (let i = 0; i < apiCalls; i++) {
    const res = await getMembers(offset, 1000);
    members = [...members, ...res.members];
    offset += 1000;
  }
  return members;
};

const memberCount = async () => {
  const list = await getListStats().then((data) => data);

  const totalMembersDivided = Math.ceil(
    (list.stats.member_count +
      list.stats.cleaned_count +
      list.stats.unsubscribe_count) /
      1000
  );

  return totalMembersDivided;
};

export const addFilteredMember = (member) => ({
  type: "ADD_FILTERED_MEMBER",
  payload: member,
});

export const addFilteredMembers = (members) => ({
  type: "ADD_FILTERED_MEMBERS",
  payload: members,
});

//tag reducer exisits in root folder reducers

export const addTag = (tag) => ({
  type: "ADD_TAG",
  payload: tag,
});

const screenForTags = (members) => {
  console.log("Screening for tags");
  return (dispatch, getState) => {
    const tags = getState().tags;
    members.forEach((m) => {
      m.tags.forEach((mt) => {
        let exists = false;
        tags.forEach((t) => {
          if (t.id === mt.id) {
            exists = true;
          }
        });
        if (exists === false || tags <= 0) {
          dispatch(addTag(mt));
        }
      });
    });
  };
};
