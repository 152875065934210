import React, { useState, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { addFilteredMachines } from "../../../redux/machines/actions";
import { connect } from "react-redux";
import { sortByString, sortByNumber } from "../../tools";
import { CompanySelect } from "./companySelect";
import { CategorySelect } from "./categorySelect";
import { MakerSelect } from "./makerSelect";
import { setLoading } from "../../../redux/actions";

const MachineSearch = ({
  makers,
  categories,
  machines,
  companies,
  addFilteredMachines,
  isItLoading,
}) => {
  const history = useHistory();
  let match = useRouteMatch();
  const initSearchTerms = {
    id: "",
    Maker_id: "0",
    model: "",
    year: "",
    Comments: "",
    Category_id: "",
    Owner_id: "0",
    removed: "0",
    owned: "0",
  };
  const [searchTerms, setSearchTerms] = useState(initSearchTerms);
  const [changeing, setChangeing] = useState(false);
  const [searching, setSearching] = useState(false);
  sortByString(categories, "cat_name");
  sortByString(companies, "company_name");
  sortByString(makers, "Name");

  const handleSearch = async (e) => {
    e.preventDefault();
    history.push(`${match.path}/search-results`);
  };

  const handleClear = (e) => {
    e.preventDefault();
    setSearching(false);
    setSearchTerms(initSearchTerms);
  };

  /**
   * Gets input and sets the searchTerms object, sets Changing to True and isItLoading to True
   *
   * @param {object} e Input event object
   */
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setChangeing(true);
    isItLoading(true);
    setSearchTerms({ ...searchTerms, [name]: value });
  };

  const filterMachines = (machinesToBeFiltered) => {
    const scoredMachines = machinesToBeFiltered.map((machine) => {
      let score = 0;
      if (searchTerms.id.length > 0 && machine.machine_id === searchTerms.id) {
        score += 1;
      }

      if (
        searchTerms.Maker_id !== "0" &&
        searchTerms.Maker_id === machine.Maker_id
      ) {
        score += 1;
      }

      if (searchTerms.model.length > 0 && machine.Model != null) {
        if (
          machine.Model.search(new RegExp(searchTerms.model.trim(), "gi")) >= 0
        ) {
          score += 1;
        }
      }

      if (searchTerms.year.length > 0 && machine.ProductionYear != null) {
        if (
          machine.ProductionYear.search(
            new RegExp(searchTerms.year.trim(), "gi")
          ) >= 0
        ) {
          score += 1;
        }
      }

      if (searchTerms.Comments.length > 0) {
        if (
          machine.Comments.search(
            new RegExp(searchTerms.Comments.trim(), "gi")
          ) >= 0
        ) {
          score += 1;
        }
      }

      if (
        searchTerms.Category_id !== "0" &&
        searchTerms.Category_id === machine.Category_id
      ) {
        score += 1;
      }

      if (
        searchTerms.Owner_id !== "0" &&
        searchTerms.Owner_id === machine.Owner_id
      ) {
        score += 1;
      }

      if (
        searchTerms.removed !== "0" &&
        searchTerms.removed === String(machine.Removed)
      ) {
        score += 1;
      }

      if (
        searchTerms.owned !== "0" &&
        searchTerms.owned === String(machine.CostumerMachine)
      ) {
        score += 1;
      }

      const machineWithScore = { ...machine, score: score };
      return machineWithScore;
    });

    scoredMachines.sort((a, b) => a.score - b.score).reverse(); //make heighest scrore appear first, easier to test if score is 0.

    if (scoredMachines[0].score !== 0) {
      //Check if there is no machines
      const filteredMachines = scoredMachines.filter(
        //only keep machines with the highest score
        (m) => scoredMachines[0].score === m.score
      );
      const machinesToShow = sortByNumber(filteredMachines, "machine_id");

      addFilteredMachines(machinesToShow);
    } else {
      addFilteredMachines([]);
    }
    isItLoading(false);
  };

  const isNotSearching = () => {
    setSearching(false);
  };
  const isSearching = () => {
    setSearching(true);
  };

  useEffect(() => {
    if (
      !changeing &&
      history.location.pathname === "/es/machines/search-results"
    ) {
      if (
        searchTerms.id.length <= 0 &&
        searchTerms.Maker_id === "0" &&
        searchTerms.model.length <= 1 &&
        searchTerms.year.length <= 1 &&
        searchTerms.Comments.length <= 1 &&
        searchTerms.Category_id === "" &&
        searchTerms.Owner_id === "0" &&
        searchTerms.removed === "0" &&
        searchTerms.owned === "0"
      ) {
        isNotSearching();
      } else {
        console.log("FILTERING");
        isSearching();
        filterMachines(machines); //Dont know if using function in useEffect is wrong, but there is no JEST message telling me wrong so i will keep it for now :D
      }
    }
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setChangeing(false);
    }, 300);
    return () => clearTimeout(timer);
  }, [searchTerms]);

  return (
    <form
      className="search-form"
      data-testid="machine-search-form"
      onSubmit={(e) => {
        handleSearch(e);
      }}
    >
      <input
        type="text"
        placeholder="Id"
        onChange={(e) => handleChange(e)}
        value={searchTerms.id}
        name="id"
      />

      <MakerSelect
        change={handleChange}
        currentMaker={searchTerms.Maker_id}
        makers={makers}
      />

      <input
        type="text"
        placeholder="Model"
        onChange={(e) => handleChange(e)}
        value={searchTerms.model}
        name="model"
      />
      <input
        type="text"
        placeholder="Year"
        onChange={(e) => handleChange(e)}
        value={searchTerms.year}
        name="year"
      />
      <input
        type="text"
        placeholder="Comment"
        onChange={(e) => handleChange(e)}
        value={searchTerms.Comments}
        name="Comments"
      />
      <CategorySelect
        change={handleChange}
        currentCategory={searchTerms.Category_id}
        categories={categories}
      />

      <CompanySelect
        change={handleChange}
        currentCompany={searchTerms.Owner_id}
        companies={companies}
      />

      <select
        name="removed"
        value={searchTerms.removed}
        onChange={(e) => handleChange(e)}
      >
        <option value="0">Removed</option>
        <option key="true" value="true">
          True
        </option>
        <option key="false" value="false">
          False
        </option>
      </select>

      <select
        name="owned"
        value={searchTerms.owned}
        onChange={(e) => handleChange(e)}
      >
        <option value="0">Owned</option>
        <option key="true" value="true">
          True
        </option>
        <option key="false" value="false">
          False
        </option>
      </select>

      <button type="submit" className="search-button">
        Search
      </button>
      {searching && (
        <button
          className="outlined float-right"
          onClick={(e) => handleClear(e)}
        >
          Clear
        </button>
      )}
    </form>
  );
};

const addStateToProps = (state) => ({
  makers: state.makers,
  categories: state.categories,
  machines: state.machines,
  companies: state.companies,
});

const addDispatchToProps = (dispatch) => ({
  addFilteredMachines: (companies) => dispatch(addFilteredMachines(companies)),
  isItLoading: (status) => dispatch(setLoading(status)),
});

export default connect(
  addStateToProps,
  addDispatchToProps
)(React.memo(MachineSearch));
