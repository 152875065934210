import React from "react";

const LoadingPage = () => {
  return (
    <div className="App" data-testid="LoadingPage">
      <div className="header">
        <h1 id="header_title">Mailchimp extended</h1>
      </div>
      <div className="content">Loading...</div>
    </div>
  );
};

export default LoadingPage;
