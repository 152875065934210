import {
  getCompanies,
  getLastUpdated,
  postToES,
  putToES,
  deleteToES,
  massDeleteToES,
} from "../../apiES";
import { sortByDate } from "../../components/tools";
import { countAndCompareType } from "../countAndCompareType";
import { deleteMachines } from "../machines/actions";

const addCompanies = (companies) => ({
  type: "ADD_COMPANIES",
  payload: companies,
});

const updateCompanies = (companies) => ({
  type: "UPDATE_COMPANIES",
  payload: companies,
});

export const fetchAllCompanies = () => {
  return (dispatch) => {
    getCompanies().then((data) => {
      if (typeof data !== "undefined") {
        dispatch(addCompanies(data));
      }
    });
  };
};

/* Replaced with fetchUpdatedCompanies

const fetchLatestCompanies = () => {
  return (dispatch, getState) => {
    const companiesList = [...getState().companies];
    const latestCompaniesId = sortByDate([...companiesList], "created_at");
    getLatestCompanies(getState().esToken, latestCompaniesId[0].id).then(
      (data) => {
        if (typeof data !== "undefined") {
          dispatch(updateCompanies(data));
        }
      }
    );
  };
};
*/

const fetchUpdatedCompanies = () => {
  return (dispatch, getState) => {
    const companiesList = [...getState().companies];
    const latestCompanyDate = sortByDate([...companiesList], "updatedAt");
    getLastUpdated("companies", latestCompanyDate[0].updatedAt).then((data) => {
      if (typeof data !== "undefined" && data.length !== 0) {
        dispatch(updateCompanies(data));
      }
    });
  };
};

export const fetchCompanies = () => {
  return async (dispatch, getState) => {
    const companiesLength = getState().companies.length;
    const inSyncWithApi = await countAndCompareType(
      "companies",
      companiesLength
    );

    if (companiesLength > 0 && inSyncWithApi) {
      dispatch(fetchUpdatedCompanies());
    } else {
      dispatch(fetchAllCompanies());
    }
  };
};

const addCompany = (company) => ({
  type: "ADD_COMPANY",
  payload: company,
});

export const postCompany = (company) => {
  return (dispatch) => {
    postToES("companies", company)
      .then((data) => dispatch(addCompany(data)))
      .catch((error) => console.log(error));
  };
};

const editCompany = (company) => ({
  type: "EDIT_COMPANY",
  payload: company,
});

export const putCompany = (company) => {
  return (dispatch) => {
    putToES("companies", company)
      .then(() => dispatch(editCompany(company)))
      .catch((error) => console.log(error));
  };
};

const deleteCompany = (company) => ({
  type: "DELETE_COMPANY",
  payload: company,
});

export const removeCompany = (company) => {
  return (dispatch, getState) => {
    deleteToES("companies", company)
      .then(() => dispatch(deleteCompany(company)))
      .catch((err) => console.log(err));
    const machinesToDelete = getState().machines.filter(
      (m) => m.Owner_id === company.company_id
    );
    console.log(machinesToDelete);
    massDeleteToES("machines", machinesToDelete)
      .then(() => dispatch(deleteMachines(machinesToDelete)))
      .catch((err) => console.log(err));
  };
};

export const addFilteredCompany = (company) => ({
  type: "ADD_FILTERED_COMPANY",
  payload: company,
});

export const addFilteredCompanies = (company) => ({
  type: "ADD_FILTERED_COMPANIES",
  payload: company,
});
