import React from "react";
import { connect } from "react-redux";
import CompaniesList from "./companiesList";

const CompaniesSearchResult = ({ filteredCompanies, isLoading }) => {
  let result;
  if (isLoading === true) {
    result = <div className="loading-text">Loading</div>;
  } else if (filteredCompanies.length > 0) {
    result = <CompaniesList list={filteredCompanies} />;
  } else {
    result = <div className="loading-text">No matches</div>;
  }

  return (
    <div>
      <h2>Search result</h2>
      {result}
    </div>
  );
};

const mapStateToProps = (state) => ({
  filteredCompanies: state.filteredCompanies,
  isLoading: state.isLoading,
});

export default connect(mapStateToProps)(CompaniesSearchResult);
