import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { putMachine, removeMachine } from "../../../redux/machines/actions";
import { MakerSelectRequired } from "./makerSelect";
import { CategorySelectRequierd } from "./categorySelect";
import { CompanySelectRequired } from "./companySelect";
import { formatDate, areYouSure } from "../../tools";
import { sendMessage } from "../../sendMessage";
import { MachineCommentShortcuts } from "./textShortcuts";

const EditMachineForm = ({
  editMachineCall,
  removeMachineCall,
  makers,
  categories,
  machines,
  companies,
  history,
}) => {
  const machineIdFromUrl = new URLSearchParams(window.location.search).get(
    "id"
  );

  const initMachine = {
    Maker_id: "",
    Model: "",
    LastContact: "",
    ProductionYear: "",
    Hours: "",
    ImpactHours: "",
    Kw: "",
    Bar: "",
    M3min: "",
    Comments: "",
    Price: "",
    Removed: false,
    CostumerMachine: false,
    PictureLink: "",
    Category_id: "",
    Owner_id: "",
    machine_id: "",
  };
  const [machine, setMachine] = useState(initMachine);
  const [lastUpdated, setLastUpdated] = useState("");

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (name === "CostumerMachine" || name === "Removed") {
      const bool = value === "true" ? true : false;
      setMachine({ ...machine, [name]: bool });
    } else {
      setMachine({ ...machine, [name]: value });
    }
  };

  const handleRemove = (e, m) => {
    e.preventDefault();
    if (
      areYouSure(
        "remove",
        `${m.makerName} ${m.Model}`,
        "This will remove the machine permanently."
      )
    ) {
      //console.log("removed", m);
      sendMessage(`Removed ${m.Model}`, "success");
      removeMachineCall(m);
      history.goBack();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (lastUpdated === machine.LastContact) {
      let updatedMachine = { ...machine, LastContact: formatDate(new Date()) };
      editMachineCall(updatedMachine);
    } else {
      editMachineCall(machine);
    }
    history.goBack();
  };

  useEffect(() => {
    const machineFromId = machines.find(
      (m) => m.machine_id === machineIdFromUrl
    );

    if (machineFromId) {
      if (machineFromId.Removed == null) {
        machineFromId.Removed = false;
      }
      if (machineFromId.CostumerMachine == null) {
        machineFromId.CostumerMachine = false;
      }
      /* for (let key in machineFromId) {
        if (machineFromId[key] === null) {
          machineFromId[key] = "";
        }
      } */

      setMachine(machineFromId);
      setLastUpdated(machineFromId.LastContact);
    }
  }, [machineIdFromUrl, machines]);

  return (
    <div>
      <button className="back_button" onClick={() => history.goBack()}>
        Back
      </button>
      <form onSubmit={(e) => handleSubmit(e)}>
        <h3>Add Machine</h3>
        <div className="group">
          <div className="field">
            <CategorySelectRequierd
              change={handleChange}
              currentCategory={machine.Category_id}
              categories={categories}
            />
          </div>
          <div className="field">
            <CompanySelectRequired
              change={handleChange}
              currentCompany={machine.Owner_id}
              companies={companies}
            />
          </div>
        </div>
        <div className="group">
          <div className="field">
            <MakerSelectRequired
              change={handleChange}
              currentMaker={machine.Maker_id}
              makers={makers}
            />
          </div>
          <div className="field">
            <input
              type="text"
              name="Model"
              id="model-input"
              placeholder=""
              value={machine.Model}
              onChange={(e) => handleChange(e)}
              required
            />
            <label htmlFor="model-input">Model</label>
          </div>
          <div className="field">
            <input
              type="text"
              name="ProductionYear"
              placeholder=""
              value={machine.ProductionYear}
              onChange={(e) => handleChange(e)}
            />
            <label>Year</label>
          </div>
          <div className="field">
            <input
              type="text"
              name="Hours"
              placeholder=""
              value={machine.Hours}
              onChange={(e) => handleChange(e)}
            />
            <label>Hours</label>
          </div>
          <div className="field">
            <input
              type="text"
              name="ImpactHours"
              placeholder=""
              value={machine.ImpactHours}
              onChange={(e) => handleChange(e)}
            />
            <label>Impact hours</label>
          </div>
          <div className="field">
            <input
              type="text"
              name="Price"
              placeholder=""
              value={machine.Price}
              onChange={(e) => handleChange(e)}
            />
            <label>Price</label>
          </div>
        </div>
        <div className="group">
          <div className="field">
            <input
              type="text"
              name="Kw"
              placeholder=""
              value={machine.Kw}
              onChange={(e) => handleChange(e)}
            />
            <label>Kw</label>
          </div>
          <div className="field">
            <input
              type="text"
              name="Bar"
              placeholder=""
              value={machine.Bar}
              onChange={(e) => handleChange(e)}
            />
            <label>Bar</label>
          </div>
          <div className="field">
            <input
              type="text"
              name="M3min"
              placeholder=""
              value={machine.M3min}
              onChange={(e) => handleChange(e)}
            />
            <label>M3min</label>
          </div>
        </div>
        <div className="group">
          <div className="field">
            <input
              type="text"
              name="Comments"
              id="comment-input"
              placeholder=""
              value={machine.Comments}
              onChange={(e) => handleChange(e)}
            />
            <label>Comment</label>
            <MachineCommentShortcuts />
          </div>
          <div className="field">
            <input
              type="text"
              name="LastContact"
              placeholder=""
              value={machine.LastContact}
              onChange={(e) => handleChange(e)}
            />
            <label>Last contact</label>
          </div>
          <div className="field">
            <select
              name="Removed"
              value={machine.Removed}
              onChange={(e) => handleChange(e)}
            >
              <option key="false" value="false">
                False
              </option>
              <option key="true" value="true">
                True
              </option>
            </select>
            <label>Removed</label>
          </div>
          <div className="field">
            <select
              name="CostumerMachine"
              value={machine.CostumerMachine}
              onChange={(e) => handleChange(e)}
            >
              <option key="false" value="false">
                False
              </option>
              <option key="true" value="true">
                True
              </option>
            </select>
            <label>Costumer Machine</label>
          </div>
        </div>
        <div className="group">
          <div className="field">
            <input
              type="text"
              name="PictureLink"
              placeholder=""
              value={machine.PictureLink}
              onChange={(e) => handleChange(e)}
            />
            <label>Picture link</label>
          </div>
        </div>

        <button
          type="button"
          className="danger"
          onClick={(e) => handleRemove(e, machine)}
        >
          Remove machine
        </button>
        <button type="submit" className="link-space">
          Save changes
        </button>
      </form>
    </div>
  );
};

const setStateToProps = (state) => ({
  makers: state.makers,
  machines: state.machines,
  categories: state.categories,
  companies: state.companies,
});

const setDispatchToProps = (dispatch) => ({
  editMachineCall: (machine) => dispatch(putMachine(machine)),
  removeMachineCall: (machine) => dispatch(removeMachine(machine)),
});

export default withRouter(
  connect(setStateToProps, setDispatchToProps)(EditMachineForm)
);
