import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import MachinesList from "./machinesList";

const SearchResult = ({ filteredMachines, isLoading }) => {
  const [showAll, setShowAll] = useState(false);
  const [machines, setMachines] = useState([]);
  const [machinesNotRemoved, setMachinesNotRemoved] = useState([]);
  const [machinesToShow, setMachinesToShow] = useState([]);
  const [showRemovedMachines, setShowRemovedMachines] = useState(false);
  const [listLength, setListLength] = useState(0);

  const handleShowButton = (e) => {
    e.preventDefault();
    setShowAll(!showAll);
  };

  const handleRemoveButton = (e) => {
    e.preventDefault();
    if (showRemovedMachines === false) {
      setMachinesToShow(filteredMachines);
      setShowRemovedMachines(true);
    } else {
      setMachinesToShow(machinesNotRemoved);
      setShowRemovedMachines(false);
    }
  };

  const Result = () => {
    if (isLoading === true) {
      return <div className="loading-text">Loading</div>;
    } else if (machines.length > 0 && showAll === false) {
      return <MachinesList list={machinesToShow.slice(0, 15)} />;
    } else if (machines.length > 0 && showAll === true) {
      return <MachinesList list={machinesToShow} />;
    } else {
      return <div className="loading-text">No matches</div>;
    }
  };

  const headerText = (
    <h2>
      Search result -{" "}
      {showAll
        ? machinesNotRemoved.length
        : listLength > 15
        ? "15"
        : machinesNotRemoved.length}{" "}
      shown of {listLength} found{" "}
      <small>({listLength - machinesNotRemoved.length} marked removed)</small>
    </h2>
  );

  const headerTextWithRemoved = (
    <h2>
      Search result -{" "}
      {showAll ? listLength : listLength > 15 ? "15" : listLength} shown of{" "}
      {listLength} found{" "}
      <small>({listLength - machinesNotRemoved.length} marked removed)</small>
    </h2>
  );

  useEffect(() => {
    if (Array.isArray(filteredMachines)) {
      setMachines(filteredMachines);
      setMachinesNotRemoved(filteredMachines.filter((m) => m.Removed !== true));
      setListLength(filteredMachines.length);
    } else {
      setMachines([]);
      setMachinesNotRemoved([]);
    }
  }, [filteredMachines]);

  //checks if you want to show sold machines or not
  useEffect(() => {
    showRemovedMachines
      ? setMachinesToShow(machines)
      : setMachinesToShow(machinesNotRemoved);
  }, [machinesNotRemoved, machines, showRemovedMachines]);

  return (
    <div>
      {showRemovedMachines ? headerTextWithRemoved : headerText}
      <button
        onClick={(e) => handleShowButton(e)}
        className={showAll ? "button-selected" : ""}
      >
        {showAll ? "Show less" : "Show all"}
      </button>
      <button
        onClick={(e) => handleRemoveButton(e)}
        className={
          showRemovedMachines
            ? "link-space-left button-selected"
            : "link-space-left"
        }
      >
        {showRemovedMachines === false ? "Show removed" : "Hide removed"}
      </button>

      <Result />
    </div>
  );
};

const mapStateToProps = (state) => ({
  filteredMachines: state.filteredMachines,
  isLoading: state.isLoading,
});

export default connect(mapStateToProps)(SearchResult);
