import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  editApiAndReduxMember,
  createAndRemoveApiAndReduxMember,
} from "../../redux/members/actions";
import { countries } from "../countries";
import { emailExists, areYouSure } from "../tools";

const EditMemberForm = ({
  editApiAndReduxMember,
  createAndRemoveApiAndReduxMember,
  tags,
  members,
}) => {
  let history = useHistory();
  const searchParams = new URLSearchParams(window.location.search);
  const memberID = searchParams.get("id");

  const initMember = {
    id: "",
    list_id: "",
    email_address: "",
    status: "subscribed",
    street_address: "",
    street_address2: "",
    city: "",
    state: "",
    zipcode: "",
    country: "",
    FNAME: "",
    LNAME: "",
    PHONE: "",
    COMPANY: "",
    tags: [],
  };

  const [member, setMember] = useState(initMember);
  const [memberOldEmail, setMemberOldEmail] = useState("");
  const [memberOldStatus, setMemberOldStatus] = useState("");

  const sortedTags = tags.sort(function (a, b) {
    var nameA = a.name.toUpperCase();
    var nameB = b.name.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    if (name === "tags") {
      setMemberTag(value);
    } else if (name === "email_address") {
      emailExists(value, members, e.target, memberOldEmail);
      setMember({ ...member, [name]: value });
    } else {
      setMember({ ...member, [name]: value });
    }
    document.getElementById("tag_selector").value = 0;
  };

  //Check if email address has been cleaned
  const contactIsCleaned = (oldStatus, currentMail, OldMail) => {
    let input = document.getElementById("email_address_field");
    if (oldStatus === "cleaned" && currentMail === OldMail) {
      input.setCustomValidity("This address is cleaned and do not work");
      input.reportValidity();
    }
  };

  const setMemberTag = (id) => {
    const tagToAdd = tags.find((t) => t.id === parseInt(id));
    console.log(tagToAdd);
    console.log(member.tags);
    for (let i = 0; i < member.tags.length; i++) {
      if (
        member.tags[i].id === tagToAdd.id &&
        member.tags[i].status === "inactive"
      ) {
        const editedTagList = member.tags;
        editedTagList[i].status = "active";
        return setMember({ ...member, tags: editedTagList });
      } else if (member.tags[i].id === tagToAdd.id) {
        //tag already exist
        return;
      }
    }
    const newTagList = [...member.tags, { ...tagToAdd, status: "active" }];
    return setMember({ ...member, tags: newTagList });
  };

  /**
   * Set tag to be removed as "inactive"
   * @param {object} e event object
   * @param {number} id id of tag to removed
   */
  const removeMemberTag = (e, id) => {
    e.preventDefault();
    const newTagList = member.tags.map((m) =>
      m.id === id ? (m = { ...m, status: "inactive" }) : m
    );
    setMember({ ...member, tags: newTagList });
  };

  const displayMemberTags = () => (
    <div className="tag_row">
      {member.tags
        .filter((f) => f.status !== "inactive")
        .map((t) => {
          return (
            <div key={t.id} className="tag">
              {t.name}{" "}
              <button href="#" onClick={(e) => removeMemberTag(e, t.id)}>
                X
              </button>
            </div>
          );
        })}
    </div>
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (memberOldStatus === "cleaned" && member.status !== "cleaned") {
      const tagsOnlyNames = member.tags.map((t) => t.name);
      const apiFormat = {
        email_address: member.email_address,
        status: member.status,
        merge_fields: {
          FNAME: member.FNAME,
          LNAME: member.LNAME,
          ADDRESS: {
            addr1: member.street_address || ".",
            addr2: member.street_address2 || ".",
            city: member.city || ".",
            state: member.state || ".",
            zip: member.zipcode || ".",
            country: member.country,
          },
          COMPANY: member.COMPANY,
          PHONE: member.PHONE,
          COUNTRY2: member.country,
        },
        tags: tagsOnlyNames,
      };
      if (
        areYouSure(
          "remove",
          "This will perma delete the old email address( " +
            memberOldEmail +
            " ) and you wont be able to use it again."
        )
      ) {
        createAndRemoveApiAndReduxMember(apiFormat, member.id);
      }
    } else {
      const apiFormat = {
        id: member.id,
        list_id: member.list_id,
        email_address: member.email_address,
        status: member.status,
        merge_fields: {
          FNAME: member.FNAME,
          LNAME: member.LNAME,
          ADDRESS: {
            addr1: member.street_address || ".",
            addr2: member.street_address2 || ".",
            city: member.city || ".",
            state: member.state || ".",
            zip: member.zipcode || ".",
            country: member.country,
          },
          COMPANY: member.COMPANY,
          PHONE: member.PHONE,
          COUNTRY2: member.country,
        },
        tags: member.tags,
      };
      editApiAndReduxMember(apiFormat);
    }

    history.goBack();
  };

  useEffect(() => {
    if (members.length > 0) {
      const selectedMember = members.find((m) => m.id === memberID);
      if (typeof selectedMember !== "undefined") {
        const updatedMember = {
          id: selectedMember.id || "",
          list_id: selectedMember.list_id || "",
          email_address: selectedMember.email_address || "",
          status: selectedMember.status,
          street_address: selectedMember.merge_fields.ADDRESS.addr1 || "",
          street_address2: selectedMember.merge_fields.ADDRESS.addr2 || "",
          city: selectedMember.merge_fields.ADDRESS.city || "",
          state: selectedMember.merge_fields.ADDRESS.state || "",
          zipcode: selectedMember.merge_fields.ADDRESS.zip || "",
          country: selectedMember.merge_fields.ADDRESS.country || "",
          FNAME: selectedMember.merge_fields.FNAME || "",
          LNAME: selectedMember.merge_fields.LNAME || "",
          PHONE: selectedMember.merge_fields.PHONE || "",
          COMPANY: selectedMember.merge_fields.COMPANY || "",
          tags: selectedMember.tags || [],
        };
        Object.entries(updatedMember).forEach(([key, value]) => {
          if (value === ".") {
            updatedMember[key] = "";
          }
        });
        setMemberOldEmail(updatedMember.email_address);
        setMemberOldStatus(updatedMember.status);
        setMember(updatedMember);
      }
    }
  }, [members, memberID]);

  useEffect(() => {
    contactIsCleaned(memberOldStatus, member.email_address, memberOldEmail);
  }, [member, memberOldStatus, memberOldEmail]);

  return (
    <div>
      <button className="back_button" onClick={() => history.goBack()}>
        Back
      </button>
      <form
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <h3>Edit member</h3>
        <div className="group">
          <div className="field">
            <input
              type="text"
              name="COMPANY"
              value={member.COMPANY}
              onChange={(e) => handleChange(e)}
            />
            <label>Company name</label>
          </div>
        </div>
        <div className="group">
          <div className="field">
            <input
              type="text"
              name="email_address"
              id="email_address_field"
              className="email-input"
              value={member.email_address}
              onChange={(e) => handleChange(e)}
              required
            />
            <label>Email address *</label>
          </div>
          <div className="field">
            <input
              type="text"
              name="FNAME"
              value={member.FNAME}
              onChange={(e) => handleChange(e)}
            />
            <label>First name</label>
          </div>
          <div className="field">
            <input
              type="text"
              name="LNAME"
              value={member.LNAME}
              onChange={(e) => handleChange(e)}
            />
            <label>Last name</label>
          </div>
          <div className="field">
            <input
              type="text"
              name="PHONE"
              value={member.PHONE}
              onChange={(e) => handleChange(e)}
            />
            <label>Phone number</label>
          </div>
        </div>
        <div className="group">
          <div className="field">
            <input
              type="text"
              name="street_address"
              value={member.street_address}
              onChange={(e) => handleChange(e)}
            />
            <label>Street address</label>
          </div>
          <div className="field">
            <input
              type="text"
              name="street_address2"
              value={member.street_address2}
              onChange={(e) => handleChange(e)}
            />
            <label>Street address 2</label>
          </div>
          <div className="field">
            <input
              type="text"
              name="city"
              value={member.city}
              onChange={(e) => handleChange(e)}
            />
            <label>City</label>
          </div>
          <div className="field">
            <input
              type="text"
              name="zipcode"
              value={member.zipcode}
              onChange={(e) => handleChange(e)}
            />
            <label>Postal/Zip</label>
          </div>
          <div className="field">
            <input
              type="text"
              name="state"
              value={member.state}
              onChange={(e) => handleChange(e)}
            />
            <label>State/Prov/Region</label>
          </div>
          <div className="field">
            <select
              name="country"
              value={member.country}
              onChange={(e) => handleChange(e)}
            >
              {countries.map((c) => {
                return (
                  <option key={c.code} value={c.code}>
                    {c.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="group">
          {displayMemberTags()}
          <select
            id="tag_selector"
            name="tags"
            onChange={(e) => handleChange(e)}
            defaultValue="0"
          >
            <option disabled value="0">
              Select tags *
            </option>
            {sortedTags.map((t) => {
              return (
                <option key={t.id} value={t.id}>
                  {t.name}
                </option>
              );
            })}
          </select>

          <select
            name="status"
            value={member.status}
            onChange={(e) => handleChange(e)}
            required
          >
            <option value="subscribed">Subscribed</option>
            <option value="unsubscribed">Unsubscribed</option>
            <option value="cleaned">cleaned</option>
            <option value="pending">pending</option>
            <option value="transactional">transactional</option>
          </select>
          <button type="submit">Update member</button>
        </div>
      </form>
      {memberOldStatus === "cleaned" && (
        <div id="form-warning">
          This email is cleaned and cant be used, change status from cleaned to
          subscribed and a new contact will be created on update.
        </div>
      )}
    </div>
  );
};

const addStateToProps = (state) => ({
  tags: state.tags,
  members: state.members,
});

const addDispatchToProps = (dispatch) => ({
  editApiAndReduxMember: (member) => dispatch(editApiAndReduxMember(member)),
  createAndRemoveApiAndReduxMember: (member, oldMemberId) =>
    dispatch(createAndRemoveApiAndReduxMember(member, oldMemberId)),
});

export default connect(addStateToProps, addDispatchToProps)(EditMemberForm);
