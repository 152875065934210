import React from "react";
import ApplicationPage from "./components/applicationPage";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import LoadingPage from "./components/loadingPage";

const App = () => {
  const authRequest = {
    scopes: ["user.read"],
  };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      loadingComponent={LoadingPage}
    >
      <ApplicationPage />
    </MsalAuthenticationTemplate>
  );
};

export default App;
