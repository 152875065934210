import React, { useState, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { addFilteredMembers } from "../../redux/members/actions";
import { connect } from "react-redux";
import { countries } from "../countries";
import { NavLink } from "react-router-dom";

const Search = ({ members, tags, addFilteredMembers }) => {
  const history = useHistory();
  let match = useRouteMatch();
  const initSearchTerms = {
    email: "",
    name: "",
    company: "",
    tag: "",
    country: "",
    status: "",
  };
  const [searchTerms, setSearchTerms] = useState(initSearchTerms);
  const [changeing, setChangeing] = useState(false);
  const [searching, setSearching] = useState(false);
  let timer = null;

  const handleSearch = async (e) => {
    e.preventDefault();
    history.push(`${match.path}/search-results`);
  };

  const handleClear = () => {
    setSearching(false);
    setSearchTerms(initSearchTerms);
  };

  /**
   * Waits for input to stop and then updates searchTerms with target name and value
   *
   * @param {object} e Input event object
   * @return {object} Updated searchTerms with targeted name and value
   */
  const handleChange = (e) => {
    const { name, value } = e.target;
    setChangeing(true);
    clearTimeout(timer);
    timer = setTimeout(() => {
      setChangeing(false);
    }, 600);

    setSearchTerms({ ...searchTerms, [name]: value });
  };

  const filterMembers = (membersToBeFiltered) => {
    const scoredMembers = membersToBeFiltered.map((member) => {
      let memberScore = 0;
      if (
        searchTerms.email.length > 0 &&
        member.email_address.search(
          new RegExp(searchTerms.email.trim(), "gi")
        ) >= 0
      ) {
        memberScore += 1;
      }
      if (searchTerms.name.length > 0) {
        const name = `${member.merge_fields.FNAME} ${member.merge_fields.LNAME}`;
        if (name.search(new RegExp(searchTerms.name.trim(), "gi")) >= 0) {
          memberScore += 1;
        }
      }
      if (
        searchTerms.company.length > 0 &&
        member.merge_fields.COMPANY.search(
          new RegExp(searchTerms.company.trim(), "gi")
        ) >= 0
      ) {
        memberScore += 1;
      }
      if (searchTerms.country !== "0") {
        if (searchTerms.country === member.merge_fields.ADDRESS.country) {
          memberScore += 1;
        }
      }

      for (let i = 0; i < member.tags.length; i++) {
        if (member.tags[i].id === parseInt(searchTerms.tag)) {
          memberScore += 1;
        }
      }

      if (
        searchTerms.status.length > 0 &&
        member.status === searchTerms.status
      ) {
        memberScore += 1;
      }

      const memberWithScore = { ...member, score: memberScore };
      return memberWithScore;
    });

    scoredMembers.sort((a, b) => a.score - b.score).reverse();

    if (scoredMembers[0].score !== 0) {
      const filteredMembers = scoredMembers.filter(
        (m) => scoredMembers[0].score === m.score
      );
      addFilteredMembers(filteredMembers);
    } else {
      addFilteredMembers({});
    }
  };

  const isNotSearching = () => {
    setSearching(false);
  };
  const isSearching = () => {
    setSearching(true);
  };

  //Update filtered members
  useEffect(() => {
    if (!changeing) {
      if (
        searchTerms.email.length <= 0 &&
        searchTerms.name.length <= 0 &&
        searchTerms.company.length <= 0 &&
        searchTerms.country.length <= 1 &&
        searchTerms.tag.length <= 0 &&
        searchTerms.status.length <= 0
      ) {
        isNotSearching();
        //addFilteredMembers(members); //Display all members if search is empty
      } else {
        console.log(searchTerms);
        isSearching();
        filterMembers(members); //Dont know if using function in useEffect is wrong, but there is no JEST message telling me wrong so i will keep it for now :D
      }
    }
  });

  return (
    <form
      className="search-form members"
      onSubmit={(e) => {
        handleSearch(e);
      }}
    >
      <input
        type="text"
        placeholder="Company"
        onChange={(e) => handleChange(e)}
        value={searchTerms.company}
        name="company"
      />
      <input
        type="text"
        placeholder="Name"
        onChange={(e) => handleChange(e)}
        value={searchTerms.name}
        name="name"
      />
      <input
        type="text"
        placeholder="E-mail"
        onChange={(e) => handleChange(e)}
        value={searchTerms.email}
        name="email"
      />
      <select
        name="country"
        value={searchTerms.country}
        onChange={(e) => handleChange(e)}
      >
        <option value="0">Select Country</option>
        {countries.map((c) => {
          return (
            <option key={c.code} value={c.code}>
              {c.name}
            </option>
          );
        })}
      </select>
      <select
        name="tag"
        onChange={(e) => handleChange(e)}
        value={searchTerms.tag}
      >
        <option value="">Select tag</option>
        {tags.map((t) => {
          return (
            <option key={t.id} value={t.id}>
              {t.name}
            </option>
          );
        })}
      </select>
      <select
        name="status"
        value={searchTerms.status}
        onChange={(e) => handleChange(e)}
      >
        <option value="">Select status</option>
        <option value="subscribed">Subscribed</option>
        <option value="unsubscribed">Unsubscribed</option>
        <option value="cleaned">cleaned</option>
        <option value="pending">pending</option>
        <option value="transactional">transactional</option>
      </select>
      <button type="submit" className="search-button">
        Search
      </button>

      {searching && (
        <button className="outlined " onClick={() => handleClear()}>
          Clear
        </button>
      )}
      <NavLink to="/mailchimp/all-members">
        <button className="float-right space">Show all members</button>
      </NavLink>
    </form>
  );
};

const addStateToProps = (state) => ({
  members: state.members,
  tags: state.tags,
});

const addDispatchToProps = (dispatch) => ({
  addFilteredMembers: (members) => dispatch(addFilteredMembers(members)),
});

export default connect(addStateToProps, addDispatchToProps)(Search);
