import React from "react";

const SelectOptions = React.memo(({ companies }) => (
  <React.Fragment>
    {companies.map((c) => {
      return (
        <option key={c.company_id} value={c.company_id}>
          {c.company_name}
        </option>
      );
    })}
  </React.Fragment>
));

/**
 * Company select without required attribute
 * < change, currentCompany, companies />
 */
export const CompanySelect = React.memo(
  ({ change, currentCompany, companies }) => {
    return (
      <select
        name="Owner_id"
        value={currentCompany}
        onChange={(e) => change(e)}
      >
        <option value="">Select Company</option>
        <SelectOptions companies={companies} />
      </select>
    );
  }
);

/**
 * Company select with required attribute
 * < change, currentCompany, companies />
 */
export const CompanySelectRequired = React.memo(
  ({ change, currentCompany, companies }) => {
    return (
      <select
        name="Owner_id"
        value={currentCompany}
        onChange={(e) => change(e)}
        required
      >
        <option value="">Select Company *</option>
        <SelectOptions companies={companies} />
      </select>
    );
  }
);
