import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { putCategory } from "../../../redux/categories/actions";

const EditCategoryForm = ({ editCategory, categories, history }) => {
  const categoryId = new URLSearchParams(window.location.search).get("id");

  const initCategory = {
    cat_name: "",
    cat_id: 0,
  };
  const [category, setCategory] = useState(initCategory);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setCategory({ ...category, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(category);
    editCategory(category);
    history.push("/es/categories");
  };

  useEffect(() => {
    const categoryFromId = categories.find((c) => c.cat_id === categoryId);
    setCategory(categoryFromId);
  }, [categoryId, categories]);

  return (
    <div>
      <button className="back_button" onClick={() => history.goBack()}>
        Back
      </button>
      <form onSubmit={(e) => handleSubmit(e)}>
        <h3>Add Category</h3>
        <div className="group">
          <input
            type="text"
            name="cat_name"
            placeholder="category name"
            value={category.cat_name}
            onChange={(e) => handleChange(e)}
          />
        </div>

        <button type="submit">Edit category</button>
      </form>
    </div>
  );
};

const setStateToProps = (state) => ({
  categories: state.categories,
});

const setDispatchToProps = (dispatch) => ({
  editCategory: (category) => dispatch(putCategory(category)),
});

export default withRouter(
  connect(setStateToProps, setDispatchToProps)(EditCategoryForm)
);
