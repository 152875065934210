//import { sortArray } from "../../components/tools";
import { flatState } from "../flatState";

export const machines = (state = [], action) => {
  switch (action.type) {
    case "ADD_MACHINES":
      return flatState(action.payload); //(state = sortArray(action.payload, "machine_id"));
    case "ADD_MACHINE":
      return [
        ...state,
        { id: action.payload.data.id, ...action.payload.data.attributes },
      ];
    case "UPDATE_MACHINES":
      const payloadIds = new Set(action.payload.map((machine) => machine.id));
      const filteredState = state.filter(
        (machine) => !payloadIds.has(machine.id)
      ); //Filters out duplicates
      const flatPayload = flatState(action.payload);
      const updatedState = [...filteredState, ...flatPayload]; //Add what  the filter removed
      return updatedState;
    case "EDIT_MACHINE":
      console.log("payload", action.payload);
      const newState = state.map((m) =>
        m.id === action.payload.id ? (m = { ...action.payload }) : m
      );
      return newState;
    case "DELETE_MACHINE":
      const stateWithoutRemovedMachine = state.filter(
        (m) => m.id !== action.payload.id
      );
      return stateWithoutRemovedMachine;
    case "DELETE_MACHINES":
      const stateWithoutRemovedMachines = state.filter((m) => {
        for (let i = 0; i < action.payload.length; i++) {
          if (m.id === action.payload[i].id) {
            return false;
          }
        }
        return true;
      });
      return stateWithoutRemovedMachines;
    default:
      return state;
  }
};

export const filteredMachines = (state = [], action) => {
  switch (action.type) {
    case "ADD_FILTERED_MACHINE":
      return [...state, { payload: action.payload }];
    case "ADD_FILTERED_MACHINES":
      return action.payload;

    default:
      return state;
  }
};
