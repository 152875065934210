import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import MemberList from "./memberlist";

const MembersLikeCompany = ({ company, members }) => {
  const [similarMembers, setSimilarMembers] = useState([]);
  const [companyString, setCompanyString] = useState("");

  useEffect(() => {
    if (members && company) {
      const matchingMembers = members.filter(
        (m) =>
          m.merge_fields.COMPANY.trim() === company.company_name.trim() ||
          m.email_address.trim() === company.email.trim()
      );
      //console.log(matchingMembers);
      setSimilarMembers([...matchingMembers]);
    }
    //Make a string out of Company object, it will be used to add it as a Mailchimp member
    if (company) {
      for (const [key, value] of Object.entries(company)) {
        setCompanyString((s) => s + `${key}=${value}&`);
      }
    }
  }, [company, members]);

  if (similarMembers.length > 0) {
    return <MemberList list={similarMembers} />;
  } else if (company) {
    return (
      <p>
        There seems to be no similar members,{" "}
        <Link to={`/mailchimp/add-member?${companyString}`}>add a member</Link>{" "}
        to Mailchimp here!
      </p>
    );
  } else {
    return (
      <p>
        There seems to be no similar members,{" "}
        <Link to={`/mailchimp/add-member`}>add a member</Link> to Mailchimp
        here!
      </p>
    );
  }
};

const mapStateToProps = (state) => ({
  members: state.members,
});

export default connect(mapStateToProps, null)(MembersLikeCompany);
